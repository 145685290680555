import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

import { AppDispatch, RootState } from "../../services/state/store";
import { deleteAsync } from "../../services/state/audios/audiosSlice";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import RightSidebar from "./RightSidebar";
import { padZeros } from "../../utils/format";
import DropdownMenu from "../../components/ui/buttons/DropdownMenu";
import Question from "../../models/Question";
import { getAsync } from "../../services/state/audio/audioSlice";

const months = [
  "января",
  "февраля",
  "марта",
  "апреля",
  "мая",
  "июня",
  "июля",
  "августа",
  "сентября",
  "октября",
  "ноября",
  "декабря",
];

interface WordInfo {
  words?: string[];
  start_times?: number[];
  end_times?: number[];
}

const delta = 0.1;

function findCurrentWord(
  wordInfo: WordInfo,
  currentTime: number
): number | null {
  for (let i = 0; i < wordInfo.words!.length; i++) {
    if (
      wordInfo.start_times![i] - delta <= currentTime &&
      currentTime <= wordInfo.end_times![i] + delta
    ) {
      return i;
    }
  }
  return null;
}

const SpeechPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [curWordIdx, setCurWordIdx] = useState<number | null>(null);
  const audio = useSelector((state: RootState) => state.audio.audio);

  const wordsInfo: WordInfo = {
    words: audio?.words,
    start_times: audio?.start_times,
    end_times: audio?.end_times,
  };

  let formattedDate = "";
  if (audio) {
    let date = new Date(audio!.created_at.toString());
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const yearIndex = date.getFullYear();
    const hours = padZeros(date.getHours());
    const minutes = padZeros(date.getMinutes());
    const monthName = months[monthIndex];
    formattedDate = `${day} ${monthName} ${yearIndex} г. в ${hours}:${minutes}`;
  }

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAsync(Number(id!)));
  }, [dispatch, id]);

  const onDelete = () => {
    console.log("handleDelete");
    dispatch(deleteAsync(Number(id!)));
    navigate("/speeches");
  };

  const onDownload = () => {
    const fileUrl = audio?.url;
    const link = document.createElement("a");
    link.href = fileUrl!;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const updateName = async (newName: any) => {
    try {
      const requestOptions: RequestInit = {
        method: "PATCH",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: newName,
        }),
      };

      const response = await fetch(`/api/v1/audios/${id}`, requestOptions);
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error("Failed to update list name");
      }
    } catch (error: any) {
      console.log("Update failed:", error.message);
    }
  };

  let textContent = <></>;
  if (audio?.type === "interview") {
    let questionsString = audio.questions;
    const questions: Question[] = JSON.parse(questionsString);

    textContent = (
      <>
        {questions.map((question, questionIdx) => (
          <>
            <p>{`${questionIdx + 1}. ${question.text}`}</p>
            <div className="bg-gray-200 w-full p-5 rounded-2xl">
              <p>
                {audio?.words.map((word, wordIdx) => {
                  let start_time = audio.start_times[wordIdx];
                  let end_time = audio.end_times[wordIdx];
                  let question_start_time = question.start_time;
                  let question_end_time = 100000;
                  if (questionIdx !== questions.length - 1) {
                    question_end_time = questions[questionIdx + 1].start_time;
                  }

                  // Игнорируем все слова до вопроса
                  if (question_start_time > end_time) {
                    return "";
                  }

                  // Игнорируем все слова после вопроса
                  if (question_end_time < start_time) {
                    return "";
                  }

                  let content;
                  if (wordIdx === curWordIdx) {
                    content = (
                      <div className="bg-yellow-300 inline-block rounded">
                        <span className="text-red-700 mx-1">{word}</span>
                      </div>
                    );
                  } else if (word === "э" || word === "эм" || word === "ну") {
                    content = (
                      <div className="bg-red-700 inline-block rounded mx-1 px-1">
                        <span className="text-white">{word}</span>
                      </div>
                    );
                  } else {
                    content = (
                      <div className="inline-block">
                        <span className="mx-1">{word}</span>
                      </div>
                    );
                  }

                  return content;
                })}
              </p>
            </div>
          </>
        ))}
      </>
    );
  } else {
    textContent = (
      <div className="bg-gray-200 w-full p-5 rounded-2xl">
        <p>
          {audio?.words.map((word, idx) => {
            let content;
            if (idx === curWordIdx) {
              content = (
                <div className="bg-yellow-300 inline-block rounded">
                  <span className="text-red-700 mx-1">{word}</span>
                </div>
              );
            } else if (word === "э" || word === "эм" || word === "ну") {
              content = (
                <div className="bg-red-700 inline-block rounded mx-1 px-1">
                  <span className="text-white">{word}</span>
                </div>
              );
            } else {
              content = (
                <div className="inline-block">
                  <span className="mx-1">{word}</span>
                </div>
              );
            }

            return content;
          })}
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white h-screen w-full flex">
      <LeftSidebar></LeftSidebar>
      <div className="w-full  ml-56 flex flex-col">
        <div className="bg-gray-100 h-16 px-4 py-1 flex items-center justify-center">
          <div className="w-full flex flex-col items-center justify-center">
            <h1
              className="text-xl px-2 focus:bg-white hover:bg-purple-200 rounded focus:shadow-outline appearance-none"
              contentEditable
              onBlur={(e) => updateName(e.target.innerText)}
            >
              {audio?.title}
            </h1>
            <p className="text-gray-700">{formattedDate}</p>
          </div>
          <DropdownMenu onDelete={onDelete} onDownload={onDownload} />
        </div>
        <div className="h-full flex justify-between items-start">
          <div className="px-6 w-2/3 py-4 flex flex-col gap-4 items-center justify-center">
            <div className="">
              <ReactPlayer
                url={audio?.url}
                onProgress={(progress) => {
                  console.log("playedSeconds", progress.playedSeconds);

                  const currentWord = findCurrentWord(
                    wordsInfo,
                    progress.playedSeconds
                  );
                  setCurWordIdx(currentWord);
                }}
                progressInterval={10}
                controls
              />
            </div>
            <div className="w-full overflow-y-auto max-h-96 flex flex-col gap-4">
              {/* <div className="bg-gray-200 w-full p-5 rounded-2xl">
                <p>{audio?.text}</p>
              </div> */}
              {textContent}
            </div>
          </div>
          <div className="w-1/3 h-full">
            <RightSidebar audio={audio}></RightSidebar>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeechPage;
