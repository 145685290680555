import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";

import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";

import Audio from "./../models/Audio";
import { padZeros } from "../utils/format";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../services/state/store";
import { deleteAsync } from "../services/state/audios/audiosSlice";
import { FILLER_WORDS_MAX, SPEECH_SPEED_MAX, SPEECH_SPEED_MIN } from "../constants";

const months = ["янв.", "фев.", "мар.", "апр.", "май.", "июн.", "июл.", "авг.", "сен.", "окт.", "ноя.", "дек."];

let typeMap: Map<string, string> = new Map([
  ["interview", "Интервью"],
  ["presentation", "Самопрезентация"],
  ["uploaded", "Загружено"],
]);

function formatFiller(filler: number) {
  const isFillerWordsOk = filler <= FILLER_WORDS_MAX;
  if (isFillerWordsOk) {
    return <p className="text-green-700">{filler}%</p>;
  }
  return <p className="text-red-700">{filler}%</p>;
}

function formatSpeechSpeed(speechSpeed: number) {
  const isSpeechSpeedOk = SPEECH_SPEED_MIN <= speechSpeed && speechSpeed <= SPEECH_SPEED_MAX;
  if (isSpeechSpeedOk) {
    return <p className="text-green-700">{speechSpeed}</p>;
  }
  return <p className="text-red-700">{speechSpeed}</p>;
}

function formatTimeFromNanoseconds(nanoseconds: number): string {
  const totalSeconds = nanoseconds / 1e9;
  // const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedDate = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  return formattedDate;
}

const columnHelper = createColumnHelper<Audio>();

const columns = [
  columnHelper.accessor("preview_url", {
    header: () => "",
    cell: (info) => {
      return <img className="h-16" src={info.getValue()} alt="No Preview" />;
    },
  }),
  columnHelper.accessor("title", {
    header: () => "Название",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("created_at", {
    header: () => "Создан",
    cell: (info) => {
      let date = new Date(info.getValue());
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const monthName = months[monthIndex];
      const hours = padZeros(date.getHours());
      const minutes = padZeros(date.getMinutes());
      const formattedDate = `${day} ${monthName} ${hours}:${minutes}`;
      return formattedDate;
    },
  }),
  columnHelper.accessor("type", {
    header: () => "Тип",
    cell: (info) => {
      const type = typeMap.get(info.getValue());
      if (type) {
        return type;
      }
      return "-";
    },
  }),
  columnHelper.accessor("duration", {
    header: () => "Длительность",
    cell: (info) => formatTimeFromNanoseconds(info.getValue()),
  }),
  columnHelper.accessor("filler_words", {
    header: () => "% Слов-паразитов",
    cell: (info) => formatFiller(info.getValue()),
  }),
  columnHelper.accessor("words_per_min", {
    header: () => "Слов/мин",
    cell: (info) => formatSpeechSpeed(info.getValue()),
  }),
];

interface AudioProps {
  data: Audio[];
  // columns: ColumnDef<Audio>[];
}

const Table: React.FC<AudioProps> = ({
  data,
  // columns
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedAudio, setSelectedAudio] = useState<Audio | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const table = useReactTable<Audio>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch<AppDispatch>();

  const handleRightClick = (event: any, audio: Audio) => {
    console.log("handleRightClick: x=", event.clientX, "y=", event.clientY);
    event.preventDefault();
    setMenuVisible(true);
    setMenuPosition({ x: event.clientX, y: event.clientY });
    setSelectedAudio(audio);
  };

  const onDelete = (id: number) => {
    dispatch(deleteAsync(id));
  };

  const onDownload = (url: string) => {
    const fileUrl = url;
    const link = document.createElement("a");
    link.href = fileUrl!;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <table className="w-full">
        <thead className="h-10">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="text-left">
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="border pl-2 pr-9">
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="h-16 hover:bg-violet-200 cursor-pointer"
              onClick={() => navigate(`/speeches/${row.original.id}`)}
              onContextMenu={(event) => handleRightClick(event, row.original)}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="border pl-2 pr-9">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {menuVisible && (
        <div
          ref={menuRef}
          style={{
            position: "absolute",
            top: menuPosition.y,
            left: menuPosition.x,
          }}
        >
          <div className="mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <button
                onClick={() => onDelete(Number(selectedAudio!.id))}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                Удалить
              </button>
              <button
                onClick={() => onDownload(selectedAudio!.url)}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                Скачать
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
