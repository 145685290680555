import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type Resp = {
  text: string;
  speed: string;
};

interface TongueTwisterState {
  slow: string;
  whisper: string;
  quick: string;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: TongueTwisterState = {
  slow: "",
  whisper: "",
  quick: "",
  status: "idle",
  error: null,
};

const tongueTwisterSlice = createSlice({
  name: "tongueTwister",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(convertAsync.pending, (state) => {
        state.status = "loading";
        console.log("Pending convert...");
      })
      .addCase(
        convertAsync.fulfilled,
        (state, action: PayloadAction<Resp | undefined>) => {
          if (action.payload!.speed === "slow") {
            state.slow = action.payload!.text;
          } else if (action.payload!.speed === "whisper") {
            state.whisper = action.payload!.text;
          } else {
            state.quick = action.payload!.text;
          }
          state.status = "succeeded";
          console.log("Converted");
        }
      )
      .addCase(convertAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message!;
        console.log("Convert failed");
      });
  },
});

export const convertAsync = createAsyncThunk(
  "tongueTwister/convertAsync",
  async ({ file, speed }: { file: any; speed: string }) => {
    const formData = new FormData();
    formData.append("file", file);

    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
      body: formData,
    };

    try {
      const response = await fetch("/api/v1/speech2text", requestOptions);
      if (response.ok) {
        const data: Resp = await response.json();
        data.speed = speed;
        return data;
      } else {
        console.error("Failed to fetch audio list");
      }
    } catch (error) {
      console.error("Error fetching audio list:", error);
    }
  }
);

export default tongueTwisterSlice.reducer;
