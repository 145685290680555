import { Link, useLocation } from "react-router-dom";
import UploadBtn from "../ui/buttons/UploadBtn";
import { AppDispatch } from "../../services/state/store";
import { useDispatch } from "react-redux";
import { logoutAsync } from "../../services/state/user/userSlice";

const menuItems = [
  {
    name: "Интервью",
    link: "/interview",
    icon_link: "/assets/quiz.svg",
  },
  {
    name: "Самопрезентация",
    link: "/presentation",
    icon_link: "/assets/man_speak.svg",
  },
  {
    name: "Мои записи",
    link: "/speeches",
    icon_link: "/assets/save.svg",
  },
  {
    name: "Упражнения",
    link: "/exercises",
    icon_link: "/assets/exercise.svg",
  },
  {
    name: "Материалы",
    link: "/materials",
    icon_link: "/assets/article.svg",
  },
];

interface LeftSidebarProps {}

const LeftSidebar: React.FC<LeftSidebarProps> = () => {
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="bg-gray-200 w-56 pb-4 fixed top-0 left-0 bottom-0 flex flex-col">
      <div className="pt-2 px-2 flex justify-between">
        <Link to={"/"} className="flex items-center">
          <img src="/assets/logo_white.svg" alt="ProSpeech logo" />
          <p className="ml-2 mr-auto text-xl leading-6">
            Pro
            <br />
            Speech
          </p>
        </Link>
        {/* <img src="/assets/arrow_back.svg" alt="Back" /> */}
      </div>

      <ul className="my-4">
        {menuItems.map((item) => (
          <Link key={item.name} to={item.link}>
            <li className={`${item.link === location.pathname && "bg-gray-300"} hover:bg-gray-300 h-14 px-4 py-2 flex items-center`}>
              <img src={item.icon_link} alt={item.name} className="mr-7" />
              {item.name}
            </li>
          </Link>
        ))}
      </ul>

      <div className="mt-auto flex items-center justify-center">
        <UploadBtn
          className="w-40"
          onClick={() => {
            dispatch(logoutAsync());
          }}
        >
          Выйти
        </UploadBtn>
      </div>
    </div>
  );
};

export default LeftSidebar;
