import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import WebcamVideo from "../../components/WebcamVideo";

const PresentationPage = () => {
  return (
    <div className="bg-white h-screen w-full flex">
      <LeftSidebar></LeftSidebar>
      <div className="pt-6 ml-56 px-8 flex-grow">
        <h1 className="text-xl mb-4">Самопрезентация</h1>
        <div className="flex items-center justify-center">
          <WebcamVideo />
        </div>
      </div>
    </div>
  );
};

export default PresentationPage;
