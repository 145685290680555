import { configureStore } from "@reduxjs/toolkit";
import audiosReducer from "./audios/audiosSlice";
import audioReducer from "./audio/audioSlice";
import userReducer from "./user/userSlice";
import questionSlice from "./question/questionSlice";
import tongueTwisterSlice from "./tongueTwister/tongueTwisterSlice";

export const store = configureStore({
  reducer: {
    audios: audiosReducer,
    audio: audioReducer,
    user: userReducer,
    question: questionSlice,
    tongueTwister: tongueTwisterSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
