import { Link } from "react-router-dom";
import UploadBtn from "../components/ui/buttons/UploadBtn";

const HomePage: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-200">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800">Pro Speech</h1>
        <h2 className="text-2xl font-semibold text-gray-700 mt-4 mb-4">
          Добро пожаловать!
        </h2>
        <p className="text-lg text-gray-600">
          Мы поможем вам подготовиться к видеоинтервью или выступлению
        </p>
        <Link to="/interview">
          <UploadBtn className="mt-6 h-10">Начать</UploadBtn>
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
