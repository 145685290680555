import { Link } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import Audio from "../../models/Audio";
import { EYE_CONTACT_MIN, FILLER_WORDS_MAX, SPEECH_SPEED_MAX, SPEECH_SPEED_MIN } from "../../constants";

const RightSidebar = ({ audio }: { audio: Audio | null }) => {
  if (!audio) {
    return (
      <div className="bg-gray-200 h-full flex items-center justify-center">
        <Spinner></Spinner>
      </div>
    );
  }

  const isSpeechSpeedOk = SPEECH_SPEED_MIN <= audio.words_per_min && audio.words_per_min <= SPEECH_SPEED_MAX;
  const isEyeContactOk = EYE_CONTACT_MIN <= audio.eye_contact;
  const hasEyeContact = audio.eye_contact >= 0;
  const hasCentering = audio.centering_url !== "" && audio.centering_url !== null;
  const isFillerWordsOk = audio.filler_words <= FILLER_WORDS_MAX;

  const speechSpeed = (
    <div className="bg-gray-300 px-2 py-1 rounded-lg flex flex-col">
      <div className="flex items-center justify-between">
        <p>Скорость речи</p>
        <p>
          {isSpeechSpeedOk ? (
            <span className="text-green-700">{audio.words_per_min}</span>
          ) : (
            <span className="text-red-700">{audio.words_per_min}</span>
          )}{" "}
          слов/мин
        </p>
      </div>
      {!isSpeechSpeedOk && (
        <>
          <div className="bg-gray-400 w-full h-px"></div>
          <Link to="/materials/speech-speed" className="text-violet-500 no-underline hover:underline">
            Скорость речи: важнейший аспект эффективной коммуникации
          </Link>
        </>
      )}
    </div>
  );

  let eyeContact = (
    <div className="bg-gray-300 px-2 py-1 rounded-lg flex flex-col">
      <div className="flex items-center justify-between">
        <p>Зрительный контакт</p>
        <p>
          {isEyeContactOk ? (
            <span className="text-green-700">{audio.eye_contact}</span>
          ) : (
            <span className="text-red-700">{audio.eye_contact}</span>
          )}{" "}
          %
        </p>
      </div>
      {!isEyeContactOk && (
        <>
          <div className="bg-gray-400 w-full h-px"></div>
          <Link to="/materials/eye-contact" className="text-violet-500 no-underline hover:underline">
            Важность зрительного контакта
          </Link>
        </>
      )}
    </div>
  );

  let fillerWords = (
    <div className="bg-gray-300 px-2 py-1 rounded-lg flex flex-col">
      <div className="flex items-center justify-between">
        <p>Слов-паразитов</p>
        <p>
          {isFillerWordsOk ? (
            <span className="text-green-700">{audio.filler_words}</span>
          ) : (
            <span className="text-red-700">{audio.filler_words}</span>
          )}{" "}
          %
        </p>
      </div>
      {!isFillerWordsOk && (
        <>
          <div className="bg-gray-400 w-full h-px"></div>
          <Link to="/materials/filler-words" className="text-violet-500 no-underline hover:underline">
            Как избавиться от слов-паразитов
          </Link>
        </>
      )}
    </div>
  );

  let centering = (
    <div className="bg-gray-300 px-2 py-1 rounded-lg flex flex-col">
      <div className="flex items-start justify-between">
        <p>Центрирование в кадре</p>
        <div className="flex justify-center items-center">
          <img src={audio.centering_url} alt="Centering" className="w-32" />
        </div>
      </div>
      {!audio.is_in_center && (
        <>
          <div className="bg-gray-400 w-full h-px mt-0.5"></div>
          <Link to="/materials/centering" className="text-violet-500 no-underline hover:underline">
            Почему важно находиться в центре кадра
          </Link>
        </>
      )}
    </div>
  );

  let goods: JSX.Element[] = [];
  let bads: JSX.Element[] = [];
  if (isSpeechSpeedOk) {
    goods.push(speechSpeed);
  } else {
    bads.push(speechSpeed);
  }
  if (hasEyeContact) {
    if (isEyeContactOk) {
      goods.push(eyeContact);
    } else {
      bads.push(eyeContact);
    }
  }
  if (isFillerWordsOk) {
    goods.push(fillerWords);
  } else {
    bads.push(fillerWords);
  }
  if (hasCentering) {
    if (audio.is_in_center) {
      goods.push(centering);
    } else {
      bads.push(centering);
    }
  }

  return (
    <div className="bg-gray-200 h-full">
      <div className="bg-purple-200 w-full h-14 px-2 py-2 flex items-center justify-center">
        {/* <img src="/assets/arrow_back.svg" alt="Back" /> */}
        <p className="text-lg">Ваши результаты</p>
      </div>

      <div className="w-full px-2 py-2 flex flex-col gap-3">
        {goods.length > 0 && <p>🏅 Что прошло хорошо</p>}

        {goods.map((good, idx) => good)}

        {bads.length > 0 && <p>💡 Что могло быть лучше</p>}

        {bads.map((bad, idx) => bad)}
      </div>
    </div>
  );
};

export default RightSidebar;
