import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import AuthProvider from "./components/AuthProvider";

import SpeechesPage from "./pages/SpeechesPage/SpeechesPage";
import SpeechPage from "./pages/SpeechPage/SpeechPage";
import PresentationPage from "./pages/PresentationPage/PresentationPage";
import ExercisesPage from "./pages/ExercisesPage /ExercisesPage";
import SignInPage from "./pages/SignInPage/SignInPage";
import NotFoundPage from "./pages/NotFoundPage";
import FastAnalogPage from "./pages/exercises/FastAnalogPage";
import HomePage from "./pages/HomePage";
import InterviewPage from "./pages/InterviewPage/InterviewPage";
import TongueTwistersPage from "./pages/exercises/TongueTwistersPage";
import QXPage from "./pages/exercises/QXPage";
import NaturalTonePage from "./pages/exercises/NaturalTonePage";
import SignUpPage from "./pages/SignUpPage/SignUpPage";
import FillerWordsPage from "./pages/articles/FillerWordsPage";
import CenteringPage from "./pages/articles/CenteringPage";
import MaterialsPage from "./pages/MaterialsPage/MaterialsPage";
import SoundStairsPage from "./pages/exercises/SoundStairsPage";
import GrowlPage from "./pages/exercises/GrowlPage";
import VentriloquismPage from "./pages/exercises/VentriloquismPage";
import NoFillerPage from "./pages/exercises/NoFillerPage/NoFillerPage";
import SpeechSpeedPage from "./pages/articles/SpeechSpeedPage";
import EyeContactPage from "./pages/articles/EyeContactPage";

const SPEECHES_PAGE_URL = "/speeches";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/signin",
    element: <SignInPage />,
  },
  {
    path: "/signup",
    element: <SignUpPage />,
  },
  {
    path: "/interview",
    element: (
      <ProtectedRoute>
        <InterviewPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/presentation",
    element: (
      <ProtectedRoute>
        <PresentationPage />
      </ProtectedRoute>
    ),
  },
  {
    path: SPEECHES_PAGE_URL,
    element: (
      <ProtectedRoute>
        <SpeechesPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/speeches/:id",
    element: (
      <ProtectedRoute>
        <SpeechPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises",
    element: (
      <ProtectedRoute>
        <ExercisesPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/fast-analog",
    element: (
      <ProtectedRoute>
        <FastAnalogPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/tongue-twisters",
    element: (
      <ProtectedRoute>
        <TongueTwistersPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/q-x",
    element: (
      <ProtectedRoute>
        <QXPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/natural-tone",
    element: (
      <ProtectedRoute>
        <NaturalTonePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/sound-stairs",
    element: (
      <ProtectedRoute>
        <SoundStairsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/growl",
    element: (
      <ProtectedRoute>
        <GrowlPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/ventriloquism",
    element: (
      <ProtectedRoute>
        <VentriloquismPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/exercises/no-filler",
    element: (
      <ProtectedRoute>
        <NoFillerPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/materials/filler-words",
    element: (
      <ProtectedRoute>
        <FillerWordsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/materials/centering",
    element: (
      <ProtectedRoute>
        <CenteringPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/materials/speech-speed",
    element: (
      <ProtectedRoute>
        <SpeechSpeedPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/materials/eye-contact",
    element: (
      <ProtectedRoute>
        <EyeContactPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/materials",
    element: (
      <ProtectedRoute>
        <MaterialsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

function App() {
  return (
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
