import { useNavigate } from "react-router-dom";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import BackBtn from "../exercises/BackBtn";

const CenteringPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-purple-200 h-full w-full px-56 py-8 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>

      <div className="bg-white w-full shadow-md rounded-2xl px-20 pt-8 pb-8 flex flex-col items-center text-xl">
        <h1 className="text-purple-800 w-full text-5xl mb-4">
          Почему важно находиться в центре кадра во время онлайн-собеседований
        </h1>
        <img src="/assets/centering_1.png" alt="" className="mb-4" />
        <p className="mb-4">
          В век дистанционного взаимодействия и онлайн-собеседований, правильная постановка камеры и положение в кадре
          играют важную роль в формировании впечатлений и успешном прохождении собеседования. В этой статье мы
          рассмотрим пять ключевых преимуществ нахождения в центре кадра во время онлайн-собеседования.
        </p>
        <ul className="space-y-4 pl-2">
          <li>
            1. Улучшение коммуникации.
            <br />
            Находясь в центре кадра, вы демонстрируете свое внимание и интерес к разговору. Это помогает создать более
            тесную связь с собеседником и улучшает качество общения. Ваше лицо будет полностью видно, что позволяет вам
            эффективно использовать мимику и жесты для передачи эмоций и усиления аргументов.
          </li>
          <li>
            2. Повышение профессионального имиджа.
            <br />
            Позиционирование себя в центре кадра демонстрирует вашу сознательность и профессионализм. Это
            свидетельствует о том, что вы готовы к встрече и уделяете внимание деталям. Такое поведение может повысить
            ваш рейтинг в глазах собеседника и сделать вас более привлекательным кандидатом на должность.
          </li>
          <li>
            3. Уменьшение отвлекающих факторов.
            <br />
            Когда вы находитесь в центре кадра, окружающие объекты и фон становятся менее заметными. Это помогает
            собеседнику сосредоточиться на вас и ваших словах, а не отвлекаться на окружение. Таким образом, вы
            способствуете более эффективному и продуктивному общению. 
          </li>
        </ul>

        <video src="">VIDEO</video>

        <UploadBtn className="" onClick={() => navigate(-1)}>
          Вернуться
        </UploadBtn>
      </div>
    </div>
  );
};

export default CenteringPage;
