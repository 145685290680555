import { useNavigate } from "react-router-dom";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import BackBtn from "../exercises/BackBtn";

const FillerWordsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-purple-200 h-full w-full px-56 py-8 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>

      <div className="bg-white w-full shadow-md rounded-2xl px-20 pt-8 pb-8 flex flex-col items-center text-xl">
        <h1 className="text-purple-800 w-full text-5xl mb-4">
          Как избавиться от привычки
          <br /> использовать словесный мусор в речи
        </h1>
        <img src="/assets/filler_words_1.png" alt="" className="mb-4" />
        <p className="mb-4">
          Междометия такие как "ээ", "эм" и "аа" являются частями речи, которые выражают чувства, эмоции или отношение к
          высказываемой информации. Они могут использоваться для усиления выражения или в качестве звукового заполнителя
          в разговорной речи.
        </p>
        <p className="mb-4">
          Это не является криминалом, НО чрезмерное использование таких слов может указывать на непрофессионализм или
          неорганизованность речи. Если вы хотите улучшить свой стиль общения, сегодня мы рассмотрим несколько подробных
          способов, как это сделать.
        </p>
        <ul className="space-y-4 pl-2">
          <li>
            1. Научитесь отслеживать лишние слова. Это очень важно, так как чаще всего мы произносим их на автомате. Вот
            что стоит сделать:
            <ul className="list-disc pl-8">
              <li>
                Попросите знакомых или коллег одёргивать вас и указывать на каждое «неправильное» слово. Для окружающих
                они будут куда заметнее.
              </li>
              <li>
                Назначьте какой‑то условный штраф за лишние слова. Лишите себя десерта, похода в кино, какой‑то покупки
                — но только пусть всё будет по‑честному!
              </li>
              <li>
                Наденьте на запястье резинку и щёлкайте ею каждый раз, когда поймаете себя на очередном «это самое». Так
                вы сами начнёте раздражаться из‑за этих выражений, а мозг постепенно научится их избегать.
              </li>
            </ul>
          </li>
          <li>
            2. Прокачивайте самоконтроль.
            <ul className="list-disc pl-8">
              <li>
                Возьмите одно из ваших коронных выражений — например, «короче». И начните нарочито громко и часто
                произносить его. Попробуйте не меньше пяти раз употребить его в одном‑двух предложениях: «Короче, я тут
                услышал, что, короче, завтра будет интересный вебинар, короче, на тему правильной речи, короче! Надо
                сходить, короче!»
              </li>
              <li>
                Звучит смешно, но важно добиться, чтобы это слово резало слух. Ведь зачастую вы произносите его, сами
                того не замечая. Теперь же контролировать свою речь станет проще.
              </li>
            </ul>
          </li>
          <li>
            3. Делайте паузы.
            <br />
            <p className="pl-8">
              Паузы в речи имеют большую силу, так как они привлекают внимание собеседника и дают ему время переварить
              сказанное. Заполнение пауз "мычанием" или неуместными звуками быстро отталкивает слушателя. Чтобы
              научиться делать паузы, можно читать художественную литературу с паузами в 2-3 секунды после каждого
              слова, сохраняя выразительность. Это упражнение поможет понять, что тишина не страшна и может обогатить
              речь, делая ее более привлекательной и утонченной.
            </p>
          </li>
          <li>
            4. Расширяйте словарный запас. Вот основные способы:
            <ul className="list-disc pl-8">
              <li>
                Читайте вслух, а не про себя. Важен сам процесс проговаривания текста. Когда вы читаете про себя, то не
                задумываетесь о том, как звучит язык. А проговаривая текст, спотыкаетесь о незнакомые или трудные
                термины, заставляя мозг фиксировать на них внимание.
              </li>
              <li>
                Выписывайте незнакомые термины с пояснениями в отдельный блокнот и проговаривайте их. Так эти слова
                отложатся у вас в голове, и вы легко сможете применить их в подходящей ситуации.
              </li>
              <li>
                Играйте. Выбирайте словесные игры, в которых нужно подбирать ассоциации к одному предмету или явлению.
                Другой вариант — описывать одно слово с помощью других, не употребляя при этом однокоренных терминов.
                Подобные игры отлично развивают правильную речь, так как в процессе вы вспоминаете те слова, которые
                редко используете в ежедневном обиходе.
              </li>
              <li>
                Выпишите любимые цитаты и фразы на стикеры и развесьте их дома или в офисе. Когда на глаза попадается
                листочек с цитатой, зачитывайте его содержание вслух — так лексикон переходит из пассивного статуса в
                активный. То есть то, что вы знаете, станет тем, что вы употребляете.
              </li>
              <li>
                Интересуйтесь всем подряд. Будьте любопытны и жадны до всего нового. Феномены и явления, научные
                открытия и исторические события, интересные личности и факты — всё должно вызывать неподдельный интерес.
                Пропорционально увеличению объёма знаний будет повышаться и словарный запас. В итоге вы сможете
                поддержать любую беседу и сделаете свою речь чище.
              </li>
            </ul>
          </li>
        </ul>

        <video src="">VIDEO</video>

        <UploadBtn className="" onClick={() => navigate(-1)}>
          Вернуться
        </UploadBtn>
      </div>
    </div>
  );
};

export default FillerWordsPage;
