import React, { useEffect, useState } from "react";

interface SliderProgressBarProps {
  totalTime: number; // seconds
  onComplete: () => void; // on timer finished
}

const SliderProgressBar: React.FC<SliderProgressBarProps> = ({
  totalTime,
  onComplete,
}) => {
  const [seconds, setSeconds] = useState(0); // Начальное значение прогресса 100%

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("seconds=", seconds);
      if (seconds >= totalTime) {
        onComplete();
      }

      setSeconds((prev) => {
        return prev + 1;
      });
    }, 1000); // Обновляем прогресс каждую секунду

    return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
  }, [onComplete, seconds, totalTime]);

  //   useEffect(() => {

  //   }, []);

  return (
    <input
      id="seconds"
      type="range"
      min={0}
      max={totalTime}
      value={seconds}
      readOnly
      className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
    />
  );
};

// 0:0{Math.round(progress / 100 * totalTime / 1000)}

export default SliderProgressBar;
