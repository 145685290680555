import { useCallback, useEffect, useRef, useState } from "react";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import FormField from "../../components/ui/fields/FormField";
import CircleTimer from "./CountCircle";
import Webcam from "react-webcam";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../services/state/store";
import { useNavigate } from "react-router-dom";
import { createAsync } from "../../services/state/audios/audiosSlice";
import SelectQuestionsModal from "./SelectQuestionsModal";
import Question from "../../models/Question";
import { formatTimeFromSeconds } from "../../utils/format";
import {
  genQuestionAsync,
  insert,
  insertAnswerAsync,
  nextQuestion,
  setQuestions,
} from "../../services/state/question/questionSlice";
import Spinner from "../../components/ui/Spinner";
import MicrophoneIndicator from "../../components/MicrophoneIndicator";

const MIME_TYPE = "video/webm";
const FILE_NAME = "new_speech.webm";
const TIMER_SECONDS = 3;

const videoConstraints = {
  // aspectRatio: 0.6666666667,
  facingMode: "user",
  width: { min: 640 },
  height: { min: 480 },
};

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
};

let questions: Question[] = [];

const InterviewPage = () => {
  // Interview state
  const [isWaitForAnswerData, setIsWaitForQuestion] = useState(false);
  const [isGeneratedQuestionNow, setIsGeneratedQuestionNow] = useState(false);

  // Other
  const navigate = useNavigate();

  // View
  const [isModalOpen, setIsModalOpen] = useState(false);

  // State
  const [state, setState] = useState<"setup" | "started" | "finished">("setup");
  const [isLastQuestion, setIsLastQuestion] = useState(false);

  // Recording
  const webcamRef = useRef<Webcam>(null);
  const fullRecorderRef = useRef<MediaRecorder | null>(null);
  const answerRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [isTimerActivated, setIsTimerActivated] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [recorderState, setRecorderState] = useState<
    "uninitialized" | "initialized" | "recording" | "paused" | "stopped"
  >("uninitialized");
  const [videoEnabled, setVideoEnabled] = useState<boolean>(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  // Redux
  const dispatch = useDispatch<AppDispatch>();
  const allQuestions = useSelector((state: RootState) => state.question.all);
  const remainedQuestionIdxs = useSelector((state: RootState) => state.question.remainedIdxs);
  const selectedQuestionIdxs = useSelector((state: RootState) => state.question.selectedIdxs);
  const selectedQuestions = useSelector((state: RootState) => state.question.selectedQuestions);
  const curQuestionIdx = useSelector((state: RootState) => state.question.curQuestionIdx);
  const messages = useSelector((state: RootState) => state.question.messages);
  const status = useSelector((state: RootState) => state.question.status);
  const user = useSelector((state: RootState) => state.user.user);

  useEffect(() => {
    let timerId: NodeJS.Timer;

    const tick = () => {
      if (recorderState === "recording") {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }
    };

    timerId = setInterval(tick, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [recorderState]);

  function initFullRecorder() {
    console.log("initRecorder: webcamRef.current", webcamRef.current); // null
    console.log("initRecorder: webcamRef.current.stream", webcamRef.current?.stream);
    if (webcamRef.current && webcamRef.current.stream) {
      let recordOptions: MediaRecorderOptions = {
        mimeType: MIME_TYPE,
      };

      fullRecorderRef.current = new MediaRecorder(webcamRef.current.stream!, recordOptions);

      fullRecorderRef.current.ondataavailable = handleFullRecordData;

      setRecorderState("initialized");
      console.log("Full recorder inited");
    }
  }

  function initAnswerRecorder() {
    if (webcamRef.current && webcamRef.current.stream) {
      let recordOptions: MediaRecorderOptions = {
        mimeType: MIME_TYPE,
      };
      answerRecorderRef.current = new MediaRecorder(webcamRef.current.stream!, recordOptions);
      answerRecorderRef.current.ondataavailable = handleAnswerRecordData;
      console.log("Answer recorder inited");
    }
  }

  function handleFullRecordData({ data }: { data: Blob }) {
    console.log("Full record data: data.size=", data.size / 1024 / 1024, "Mb");
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }

  function handleAnswerRecordData({ data }: { data: Blob }) {
    console.log("Answer record data: data.size=", data.size / 1024 / 1024, "Mb");
    if (data.size > 0) {
      const file = new File([data], FILE_NAME, { type: MIME_TYPE });
      dispatch(insertAnswerAsync({ file })).then(() => dispatch(genQuestionAsync({ messages })));
      setIsWaitForQuestion(false);
    }
  }

  const startAnswerRecord = useCallback(() => {
    answerRecorderRef.current?.start();
    console.log("Answer record started");
  }, [answerRecorderRef]);

  const stopAnswerRecord = useCallback(() => {
    answerRecorderRef.current?.stop();
    console.log("Answer record stopped");
  }, [answerRecorderRef]);

  const resumeAnswerRecord = useCallback(() => {
    answerRecorderRef.current?.resume();
    console.log("Answer record resumed");
  }, [answerRecorderRef]);

  const pauseAnswerRecord = useCallback(() => {
    answerRecorderRef.current?.pause();
    console.log("Answer record paused");
  }, [answerRecorderRef]);

  const startFullRecord = useCallback(() => {
    fullRecorderRef.current?.start();
    setRecorderState("recording");
    console.log("Full record started");
  }, [setRecorderState, fullRecorderRef]);

  const stopFullRecord = useCallback(() => {
    fullRecorderRef.current?.stop();
    setRecorderState("stopped");
    console.log("Full record stopped");
  }, [setRecorderState, fullRecorderRef]);

  const resumeFullRecord = useCallback(() => {
    fullRecorderRef.current?.resume();
    setRecorderState("recording");
    console.log("Full record resumed");
  }, [setRecorderState, fullRecorderRef]);

  const pauseFullRecord = useCallback(() => {
    fullRecorderRef.current?.pause();
    setRecorderState("paused");
    console.log("Full record paused");
  }, [setRecorderState, fullRecorderRef]);

  function start() {
    console.log("start");
    setState("started");

    dispatch(setQuestions());

    setIsTimerActivated(true);
    console.log("Timer activated");
  }

  function onNextQuestion() {
    console.log(
      "Next Question: curQuestionIdx=",
      curQuestionIdx,
      " selectedQuestions.length=",
      selectedQuestions.length,
      " isGeneratedQuestionNow=",
      isGeneratedQuestionNow
    );

    if (curQuestionIdx === selectedQuestions.length - 1) {
      setIsLastQuestion(true);
    }
    dispatch(nextQuestion());
    console.log("Question changed");

    pauseFullRecord();
    if (isGeneratedQuestionNow) {
      pauseAnswerRecord();
      setIsGeneratedQuestionNow(false);

      dispatch(
        insert({
          role: "assistant",
          content: selectedQuestions[curQuestionIdx + 1],
        })
      );
    } else {
      stopAnswerRecord();
      setIsWaitForQuestion(true);
      setIsGeneratedQuestionNow(true);
    }

    setIsTimerActivated(true);
    console.log("Timer activated");
  }

  function onDone() {
    console.log("onDone");
    stopFullRecord();
    setState("finished");
  }

  function onTimerComplete() {
    console.log("Timer completed: state =", recorderState);
    setIsTimerActivated(false);

    if (recorderState === "uninitialized") {
      initFullRecorder();
      initAnswerRecorder();

      startFullRecord();
      startAnswerRecord();

      questions.push({
        text: selectedQuestions[curQuestionIdx],
        start_time: 0,
      });
    } else {
      resumeFullRecord();
      if (!isLastQuestion) {
        if (isGeneratedQuestionNow) {
          startAnswerRecord();
        } else {
          resumeAnswerRecord();
        }
      }

      questions.push({
        text: selectedQuestions[curQuestionIdx],
        start_time: seconds,
      });
    }
  }

  function onPause() {
    if (recorderState === "paused") {
      resumeFullRecord();
      resumeAnswerRecord();
    } else {
      pauseFullRecord();
      pauseAnswerRecord();
    }
  }

  function onFinish() {
    console.log("onFinish: recordedChunks[0].size: ", recordedChunks[0].size / 1024 / 1024, "Mb");
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: MIME_TYPE,
      });
      const file = new File([blob], FILE_NAME, { type: MIME_TYPE });
      dispatch(
        createAsync({
          file: file,
          type: "interview",
          questions: questions,
        })
      );
    }
    navigate("/speeches");
  }

  let content;
  console.log("Rerender");
  if (state === "setup") {
    content = (
      <div className="flex gap-6 justify-between">
        <div className="w-3/5 flex flex-col gap-5">
          <div className="bg-gray-200 w-full p-5 rounded-2xl">
            <p className="font-bold">Привет {user!.name}! 👋 Давайте пройдем интервью</p>
            <p>
              Выберите как минимум два стартовых вопроса. После каждого ответа будет сгенерирован 1 дополнительный
              вопрос на основе вашего ответа.
            </p>
          </div>
          {/* <div className="bg-gray-200 w-full p-5 rounded-2xl">
            <p className="font-bold">Настройте свое интервью</p>
            <FormField
              id="role"
              type="text"
              placeholder="Роль"
              value=""
              onChange={(e: any) => {}}
              className="w-full mt-2"
            />
            <div className="mt-2 flex gap-2">
              <FormField
                id="company"
                type="text"
                placeholder="Компания"
                value=""
                onChange={(e: any) => {}}
                className="w-full"
              />
              <FormField
                id="interviewer"
                type="text"
                placeholder="Интервьюер"
                value=""
                onChange={(e: any) => {}}
                className="w-full"
              />
            </div>
          </div> */}
          <div className="bg-gray-200 w-full p-5 rounded-2xl">
            <p className="font-bold">Добавьте вопросы</p>
            {selectedQuestionIdxs.map((question, idx) => (
              <div key={idx} className="bg-white mt-2 w-full p-2 rounded">
                {allQuestions[question]}
              </div>
            ))}
            <button
              className="bg-white hover:bg-gray-300 w-full mt-2
                            px-4 py-2 text-purple-700 rounded
                            cursor-pointer 
                            focus:outline-none focus:shadow-outline"
              onClick={() => {
                openModal();
              }}
            >
              Изменить вопросы
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="relative flex justify-center items-center">
            <Webcam
              width={640}
              height={480}
              videoConstraints={videoEnabled ? videoConstraints : false}
              mirrored={true}
              audioConstraints={audioConstraints}
              audio={true}
              muted={true}
              className="mb-4 rounded-lg bg-violet-400"
            />

            {!videoEnabled && (
              <div className="absolute rounded-full h-36 w-36 border-8 border-white flex items-center justify-center">
                <img src="/assets/videocam_off_white.svg" alt="Video Off" className="w-20 h-20" />
              </div>
            )}

            {recorderState === "uninitialized" && (
              <div className="absolute top-2 left-2 bg-gray-300 flex items-center rounded-md overflow-hidden">
                <img
                  src={videoEnabled ? "/assets/videocam.svg" : "/assets/videocam_off.svg"}
                  alt={videoEnabled ? "Video On" : "Video Off"}
                  className="py-1 px-2 cursor-pointer"
                  onClick={() => setVideoEnabled((prev) => !prev)}
                />

                {/* <div className="bg-black w-px h-5"></div> */}

                {/* <img src="/assets/settings.svg" alt="Settings" className="py-1 px-2 cursor-pointer" /> */}
              </div>
            )}

            <div className="absolute top-2 right-2 rounded-md overflow-hidden">
              <MicrophoneIndicator />
            </div>
          </div>
          <UploadBtn className="w-32 h-10" onClick={start}>
            Начать
          </UploadBtn>
        </div>
      </div>
    );
  } else if (state === "started") {
    content = (
      <div className="flex gap-6 justify-between">
        <div className="w-3/5 flex flex-col gap-5">
          {isWaitForAnswerData || status === "loading" ? (
            <div className="flex flex-col gap-2">
              <Spinner />
              Генерируем вопрос...
            </div>
          ) : (
            <div className="bg-gray-200 w-full p-5 rounded-2xl">
              <p>{selectedQuestions[curQuestionIdx]}</p>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center">
          <div className="relative flex justify-center items-center">
            <div className="relative flex justify-center items-center">
              <Webcam
                width={640}
                height={480}
                videoConstraints={videoEnabled ? videoConstraints : false}
                mirrored={true}
                audioConstraints={audioConstraints}
                audio={true}
                muted={true}
                ref={webcamRef}
                className="mb-4 rounded-lg bg-violet-400"
              />

              {!videoEnabled && (
                <div className="absolute rounded-full h-36 w-36 border-8 border-white flex items-center justify-center">
                  <img src="/assets/videocam_off_white.svg" alt="Video Off" className="w-20 h-20" />
                </div>
              )}

              <div className="absolute top-2 right-2 rounded-md overflow-hidden">
                <MicrophoneIndicator />
              </div>
            </div>
            {isTimerActivated && !(isWaitForAnswerData || status === "loading") && (
              <CircleTimer className="absolute" totalTime={TIMER_SECONDS} onComplete={onTimerComplete} />
            )}
          </div>
          {isWaitForAnswerData || status === "loading" ? (
            <Spinner></Spinner>
          ) : (
            <div className="bg-gray-200 px-4 py-3 rounded-2xl flex gap-2 items-center">
              <p className="font-bold text-lg">{formatTimeFromSeconds(seconds)}</p>
              <button
                className={
                  "hover:bg-gray-300 px-4 py-2 text-white rounded-full cursor-pointer focus:outline-none focus:shadow-outline"
                }
                onClick={onPause}
              >
                {recorderState === "paused" ? (
                  <img src="/assets/play.svg" alt="Play" />
                ) : (
                  <img src="/assets/pause.svg" alt="Pause" />
                )}
              </button>
              {isLastQuestion ? (
                <UploadBtn className="h-10" onClick={onDone}>
                  Завершить
                </UploadBtn>
              ) : (
                <UploadBtn className="h-10" onClick={onNextQuestion}>
                  Следующий вопрос
                </UploadBtn>
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else if (state === "finished") {
    content = (
      <div className="flex items-center justify-center">
        <UploadBtn className="h-10" onClick={onFinish}>
          Посмотреть результат
        </UploadBtn>
      </div>
    );
  }

  return (
    <div className="bg-white h-screen w-full flex">
      <LeftSidebar></LeftSidebar>
      <div className="w-full pt-6 ml-56 px-8">
        <h1 className="text-xl mb-4">Интервью</h1>
        {content}
        {isModalOpen && <SelectQuestionsModal onClose={closeModal} />}
      </div>
    </div>
  );
};

export default InterviewPage;
