import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import ExerciseCard from "./ExerciseCard";

const exercises = [
  {
    name: "Скороговорки",
    description: "Выберите сложность и произнесите скороговорку как можно четче.",
    link: "/exercises/tongue-twisters",
  },
  {
    name: "Быстрая аналогия",
    description: "Быстро придумывайте аналогии, не позволяя давлению влиять на вас.",
    link: "/exercises/fast-analog",
  },
  {
    name: "Кью-Икс",
    description: 'Следуйте инструкциям, а затем произнесите "кью" и "икс" для укрепления и гибкости губ и языка.',
    link: "/exercises/q-x",
  },
  {
    name: "Природный тон",
    description: "Следуйте инструкциям и произнесите низкий, глубокий звук, похожий на мычание, на выдохе.",
    link: "/exercises/natural-tone",
  },
  {
    name: "Звуковая лестница",
    description: "Развивайте дыхание и голосовые свзяки поднимаясь по ступенькам.",
    link: "/exercises/sound-stairs",
  },
  {
    name: "Рычание",
    description: "Потренируйте свою дикцию: произносите слова, растягивая букву «р».",
    link: "/exercises/growl",
  },
  {
    name: "Чревовещание",
    description: "Читайте с закрытым ртом, пытаясь четко проговаривать слова.",
    link: "/exercises/ventriloquism",
  },
  {
    name: "No Filler",
    description: "No Filler",
    link: "/exercises/no-filler",
  },
];

const ExercisesPage = () => {
  return (
    <div className="bg-white w-full flex">
      <LeftSidebar></LeftSidebar>
      <div className=" ml-56 pt-6 px-8 pb-6">
        <h1 className="text-xl mb-4">Упражнения</h1>
        <div className="flex flex-wrap justify-start gap-x-10 gap-y-10">
          {exercises.map((exercise, idx) => (
            <ExerciseCard
              key={idx}
              name={exercise.name}
              description={exercise.description}
              link={exercise.link}
            ></ExerciseCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExercisesPage;
