import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import { AppDispatch, RootState } from "../../services/state/store";
import { remove, select } from "../../services/state/question/questionSlice";

interface SelectQuestionsModalProps {
  onClose: () => void;
}

const SelectQuestionsModal: React.FC<SelectQuestionsModalProps> = ({
  onClose,
}) => {
  // Redux
  const allQuestions = useSelector((state: RootState) => state.question.all);
  const remainedQuestionIdxs = useSelector(
    (state: RootState) => state.question.remainedIdxs
  );
  const selectedQuestionIdxs = useSelector(
    (state: RootState) => state.question.selectedIdxs
  );
  const dispatch = useDispatch<AppDispatch>();

  // Functions
  function addQuestion(idx: number) {
    console.log("addQuestion: idx = ", idx);
    dispatch(select(idx));
  }

  function removeQuestion(idx: number) {
    console.log("removeQuestion: idx = ", idx);
    dispatch(remove(idx));
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="relative px-5 py-5 bg-gray-50 rounded-lg">
          {/* <div className="relative px-5 py-5 bg-red-500 rounded-lg w-150"> */}
          <div className="flex justify-end">
            <img
              src="/assets/close.svg"
              alt="Back"
              onClick={onClose}
              className="cursor-pointer"
            />
          </div>
          <div className="flex flex-col items-center">
            <h2 className="w-full font-medium text-2xl">Список вопросов</h2>
            <div className="w-full flex gap-5 mt-4">
              <div className="bg-gray-200 w-150 p-5 rounded-2xl">
                {remainedQuestionIdxs.map((questionIdx, idx) => (
                  <div
                    key={idx}
                    className="bg-white mt-2 w-full p-2 rounded flex items-center"
                  >
                    <button
                      className={
                        "hover:bg-gray-300 px-1 py-1 mr-2 text-white rounded-full cursor-pointer focus:outline-none focus:shadow-outline"
                      }
                      onClick={() => {}}
                    >
                      <img
                        src="/assets/add.svg"
                        alt="Add"
                        onClick={() => {
                          addQuestion(questionIdx);
                        }}
                      />
                    </button>
                    {allQuestions[questionIdx]}
                  </div>
                ))}
              </div>
              <div className="bg-gray-200  w-150 p-5 rounded-2xl">
                <p className="font-bold">Мои вопросы</p>
                {selectedQuestionIdxs.map((questionIdx, idx) => (
                  <div
                    key={idx}
                    className="bg-white mt-2 w-full p-2 rounded flex items-center justify-between"
                  >
                    {allQuestions[questionIdx]}
                    <button
                      className={
                        "hover:bg-gray-300 px-1 py-1 ml-2 text-white rounded-full cursor-pointer focus:outline-none focus:shadow-outline"
                      }
                      onClick={() => {}}
                    >
                      <img
                        src="/assets/close.svg"
                        alt="Remove"
                        onClick={() => {
                          removeQuestion(questionIdx);
                        }}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <UploadBtn className="w-40 h-10 mt-5" onClick={onClose}>
              Сохранить
            </UploadBtn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectQuestionsModal;
