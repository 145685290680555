import { useState } from "react";

import UploadBtn from "../../components/ui/buttons/UploadBtn";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import SliderProgressBar from "../../components/ui/SliderProgressBar";
import BackBtn from "./BackBtn";

const MIN_TIME = 60;
// const MIN_TIME = 3;
const MAX_TIME = 300;
const DEFAULT_TIME = 150;

function formatTimeFromSeconds(totalSeconds: number): string {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedDate = `${String(minutes)}:${String(seconds).padStart(
    2,
    "0"
  )}`;
  return formattedDate;
}

const videoConstraints = {
  // aspectRatio: 0.6666666667,
  facingMode: "user",
  width: { min: 640 },
  height: { min: 480 },
};

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
};

const instructions = [
  "Сядьте поближе к краешку стула.",
  "Приподнимите грудь.",
  "Подтяните живот.",
  "Голову держите ровно.",
  "Губы сомкните.",
  "Зубы — разомкните.",
  "Расслабьте лицо.",
  "Сделайте вдох через нос.",
  "Издайте негромкий низкий звук на выдохе, похожий на мычание, 3-4 секунды.",
];

const NaturalTonePage = () => {
  const [time, setTime] = useState(DEFAULT_TIME);

  const [state, setState] = useState<"setup" | "started" | "finished">("setup");

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    setTime(newValue);
  };

  function onTimerComplete() {
    console.log("onTimerComplete");
    setState("finished");
  }

  let content;
  if (state === "setup") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-4 text-2xl text-center">Природный тон</h1>
        <div className="flex items-start justify-between mb-4">
          <p className="font-bold text-xl text-purple-500">Инструкция</p>
          <UploadBtn
            onClick={() => {
              setState("started");
            }}
          >
            Начать
          </UploadBtn>
        </div>

        <div className="mb-4">
          <ul>
            {instructions.map((instruction) => (
              <li key={instruction}>
                <span>•</span> {instruction}
              </li>
            ))}
          </ul>
        </div>

        <div className="flex justify-between mb-2">
          <label htmlFor="time">Установите время выполнения упражнения</label>
          <p className="font-bold text-xl text-purple-500">
            {formatTimeFromSeconds(time)}
          </p>
        </div>
        <input
          id="time"
          type="range"
          min={MIN_TIME}
          max={MAX_TIME}
          value={time}
          onChange={handleTimeChange}
          step={10}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        />
        <div className="flex justify-between">
          <p>{formatTimeFromSeconds(MIN_TIME)}</p>
          <p>{formatTimeFromSeconds(MAX_TIME)}</p>
        </div>
      </div>
    );
  } else if (state === "started") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col items-center">
        <div className="mb-4 flex justify-center items-center">
          <Webcam
            width={600}
            height={400}
            videoConstraints={videoConstraints}
            mirrored={true}
            audioConstraints={audioConstraints}
            audio={true}
            muted={true}
          />
        </div>
        <SliderProgressBar
          totalTime={time}
          onComplete={onTimerComplete}
        ></SliderProgressBar>
        <div className="flex justify-between w-full">
          <p>{formatTimeFromSeconds(0)}</p>
          <p>{formatTimeFromSeconds(time)}</p>
        </div>
        <UploadBtn
          onClick={() => {
            setState("finished");
          }}
        >
          Завершить раньше
        </UploadBtn>
      </div>
    );
  } else if (state === "finished") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col items-center">
        <h1 className="mb-6 text-purple-900 font-bold text-2xl text-center">
          Вы выполнили упражнение Природный тон!
        </h1>
        <p className="text-center mb-4 w-150">
          Упражнение для голоса «Природный тон» помогает почувствовать природный
          центр своего голоса. Фундамент, на котором впоследствии можно
          выстроить прекрасное звучание голоса на всем диапазоне!
        </p>
        <div className="w-full flex justify-between">
          <Link to="/exercises">
            <UploadBtn className="">К упражнениям</UploadBtn>
          </Link>
          <UploadBtn
            onClick={() => {
              setState("setup");
            }}
          >
            Пройти снова
          </UploadBtn>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-200 h-screen w-full flex items-center justify-center">
      {content}
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>
    </div>
  );
};

export default NaturalTonePage;
