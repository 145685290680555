import { useCallback, useEffect, useRef, useState } from "react";

import UploadBtn from "../../../components/ui/buttons/UploadBtn";
import Webcam from "react-webcam";
import { Link } from "react-router-dom";
import BackBtn from "../BackBtn";
import { DEFAULT_TIME, MAX_TIME, MIN_TIME, S2T_INTERVAL, TIME_STEP } from "./constants";
import CircularProgressBar from "../../../components/ui/CircularProgressBar";
import { getRandomInt } from "../../../utils/random";

const MIME_TYPE = "video/webm";
const FILE_NAME = "new_speech.webm";

function formatTimeFromSeconds(totalSeconds: number): string {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedDate = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  return formattedDate;
}

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
};

const instructions = [
  "Мы выдадим вам случайную тему, на которую нужно будет говорить",
  "Говорите, пока таймер не достигнет 0",
  "Набирайте баллы за разговор без слов-заполнителей",
  "За произнесение слова-паразита вы потеряйте в 2 раза больше очков!",
];

const topics = [
  "Какой лучший комплимент, который вы когда-либо получали?",
  "Какая мировая проблема имеет для вас большое значение?",
  "Страны, которые я хотел бы посетить... 🛫",
  "Кто ваш герой/героиня детства?",
  "Объясните странное сочетание продуктов, которое, по вашему мнению, должен попробовать каждый?",
  "Если бы ты мог быть любым животным, кем бы ты был? 🐷",
];

const fillerWords = ["эм", "ээ", "ну"];

let timerId: any;

const NoFillerPage = () => {
  console.log("RERENDER!!!");

  // Recording
  const webcamRef = useRef<Webcam>(null);
  const answerRecorderRef = useRef<MediaRecorder | null>(null);

  async function handleAnswerRecordData({ data }: { data: Blob }) {
    console.log("Answer record data: data.size=", data.size / 1024 / 1024, "Mb");
    if (data.size > 0) {
      const file = new File([data], FILE_NAME, { type: MIME_TYPE });

      const formData = new FormData();
      formData.append("file", file);

      const requestOptions: RequestInit = {
        method: "POST",
        credentials: "include",
        body: formData,
      };

      try {
        const response = await fetch("/api/v1/speech2text", requestOptions);
        if (response.ok) {
          const fetchData = await response.json();
          if (fetchData.text !== "") {
            const wordsArray = fetchData.text.split(" ");
            console.log(wordsArray);

            for (const word of wordsArray) {
              if (word === "ну" || word === "эм" || word === "ээ" || word === "э") {
                setScore((prev) => prev - 30);
              } else {
                setScore((prev) => prev + 5);
              }
            }
          }
        } else {
          console.error("Failed to fetch audio list");
        }
      } catch (error) {
        console.error("Error fetching audio list:", error);
      }

      console.log("before", webcamRef.current);
      if (state === "finished" || webcamRef.current === undefined || webcamRef.current === null) {
        console.log("end record");
        return;
      }

      // start again
      startAnswerRecord();
      timerId = setTimeout(() => {
        clearTimeout(timerId);
        stopAnswerRecord();
      }, S2T_INTERVAL);
    }
  }

  function initAnswerRecorder() {
    if (webcamRef.current && webcamRef.current.stream) {
      let recordOptions: MediaRecorderOptions = {
        mimeType: MIME_TYPE,
      };
      answerRecorderRef.current = new MediaRecorder(webcamRef.current.stream!, recordOptions);
      answerRecorderRef.current.ondataavailable = handleAnswerRecordData;
      console.log("Answer recorder inited");
    } else {
      console.warn("No stream");
    }
  }

  const startAnswerRecord = useCallback(() => {
    answerRecorderRef.current?.start();
    console.log("Answer record started");
  }, [answerRecorderRef]);

  const stopAnswerRecord = useCallback(() => {
    answerRecorderRef.current?.stop();
    console.log("Answer record stopped");
  }, [answerRecorderRef]);

  function startExercise() {
    console.log("startExercise");

    initAnswerRecorder();
    startAnswerRecord();
    timerId = setTimeout(() => {
      clearTimeout(timerId);
      stopAnswerRecord();
    }, S2T_INTERVAL);
  }

  const [score, setScore] = useState(0);
  const [state, setState] = useState<"setup" | "started" | "finished">("setup");
  const [time, setTime] = useState(DEFAULT_TIME);
  const [topicIdx, setTopicIdx] = useState(0);

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    setTime(newValue);
  };

  useEffect(() => {
    if (state === "started") {
      const timer = setTimeout(() => {
        console.log("init timer completed");
        startExercise();
        clearTimeout(timer);
      }, 500);
    }
  }, [state]);

  let content;
  if (state === "setup") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-4 text-2xl text-center">No Filler</h1>
        <div className="flex items-start justify-between mb-4">
          <p className="font-bold text-xl text-purple-500">Инструкция</p>
          <UploadBtn
            onClick={() => {
              setTopicIdx(getRandomInt(0, topics.length));
              setState("started");
            }}
          >
            Начать
          </UploadBtn>
        </div>

        <div className="mb-4">
          <ul>
            {instructions.map((instruction, idx) => (
              <li key={idx}>
                <span>•</span> {instruction}
              </li>
            ))}
          </ul>
        </div>

        <p className="mb-2 text-xl">Слова-паразиты</p>

        <div className="mb-4 flex gap-2">
          {fillerWords.map((fillerWord, idx) => (
            <div key={idx} className="bg-purple-400 rounded-2xl px-4 py-1">
              {fillerWord}
            </div>
          ))}
        </div>

        <div className="flex justify-between mb-2">
          <label htmlFor="time">Установите время упражнения</label>
          <p className="font-bold text-xl text-purple-500">{formatTimeFromSeconds(time)}</p>
        </div>
        <input
          id="time"
          type="range"
          min={MIN_TIME}
          max={MAX_TIME}
          value={time}
          onChange={handleTimeChange}
          step={TIME_STEP}
          className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
        />
        <div className="flex justify-between">
          <p>{formatTimeFromSeconds(MIN_TIME)}</p>
          <p>{formatTimeFromSeconds(MAX_TIME)}</p>
        </div>
      </div>
    );
  } else if (state === "started") {
    content = (
      <div className="bg-white w-1/2 p-8 rounded-xl flex flex-col items-center">
        <Webcam
          width={0}
          height={0}
          videoConstraints={false}
          mirrored={true}
          audioConstraints={audioConstraints}
          audio={true}
          muted={true}
          ref={webcamRef}
        />

        <p className="mb-8 text-2xl font-medium text-purple-500 text-center">{topics[topicIdx]}</p>

        <div className="w-full flex justify-between">
          <div className="w-60">
            <CircularProgressBar
              totalTime={time * 1000}
              onComplete={() => {
                setState("finished");
              }}
            />
          </div>

          <div className="flex flex-col justify-between items-center">
            <div className="bg-gray-200 text-violet-700 px-2 py-1 text-3xl font-medium flex justify-center items-center rounded-xl">
              {score}
            </div>

            <UploadBtn
              onClick={() => {
                setState("finished");
              }}
            >
              Завершить раньше
            </UploadBtn>
          </div>
        </div>
      </div>
    );
  } else if (state === "finished") {
    content = (
      <div className="bg-white w-160 p-8 rounded-xl flex flex-col">
        <h1 className="mb-6 text-purple-900 font-bold text-2xl text-center">Вы выполнили упражнение No Filler!</h1>

        <div className="w-full mb-6">
          <p className="mb-4">Тема: {topics[topicIdx]}</p>
          <p className="">Очки: {score}</p>
        </div>

        <div className="flex justify-between">
          <Link to="/exercises">
            <UploadBtn className="">К упражнениям</UploadBtn>
          </Link>
          <UploadBtn
            onClick={() => {
              setState("setup");
              setScore(0);
            }}
          >
            Пройти снова
          </UploadBtn>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-200 h-screen w-full flex items-center justify-center">
      {content}
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>
    </div>
  );
};

export default NoFillerPage;
