import { useState } from "react";

import UploadBtn from "../../components/ui/buttons/UploadBtn";
import { Link } from "react-router-dom";
import BackBtn from "./BackBtn";

const instructions = [
  "Сядьте поближе к краешку стула.",
  "Приподнимите грудь.",
  "Подтяните живот.",
  "Голову держите ровно.",
  "Глубоко вдохните.",
  "На выдохе произнесите  звук «и», тяните его так долго, как только сможете.",
  "Повторите упражнение  с другими гласными – «э», «а», «о», «у».",
];

const playInstructions = [
  "После глубоко вдоха тяните «И» так долго, как только сможете",
  "После глубоко вдоха тяните «Э» так долго, как только сможете",
  "После глубоко вдоха тяните «А» так долго, как только сможете",
  "После глубоко вдоха тяните «О» так долго, как только сможете",
  "После глубоко вдоха тяните «У» так долго, как только сможете",
];

const SoundStairsPage = () => {
  const [curInstructionIdx, setCurInstructionIdx] = useState(0);
  const [state, setState] = useState<"setup" | "started" | "finished">("setup");

  let content;
  if (state === "setup") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-4 text-2xl text-center">Звуковая лестница</h1>
        <div className="flex items-start justify-between mb-4">
          <p className="font-bold text-xl text-purple-500">Инструкция</p>
          <UploadBtn
            onClick={() => {
              setState("started");
            }}
          >
            Начать
          </UploadBtn>
        </div>

        <div className="mb-4">
          <ul>
            {instructions.map((instruction) => (
              <li key={instruction}>
                <span>•</span> {instruction}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  } else if (state === "started") {
    content = (
      <div className="bg-white w-1/2 p-10 rounded-xl flex flex-col gap-8 items-center">
        <p className="font-bold text-3xl text-center">{playInstructions[curInstructionIdx]}</p>

        <UploadBtn
          onClick={() => {
            if (curInstructionIdx === playInstructions.length - 1) {
              setCurInstructionIdx(0);
              setState("finished");
            } else {
              setCurInstructionIdx((prev) => prev + 1);
            }
          }}
        >
          Дальше
        </UploadBtn>
      </div>
    );
  } else if (state === "finished") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col gap-6 items-center">
        <h1 className="text-purple-900 font-bold text-2xl text-center">
          Вы выполнили упражнение Звуковая лестница!
        </h1>
        <p className="text-center w-150">
          Верно выполняя упражнение, вы сможете прочувствовать работу резонаторов – грудной полости и пазух черепа.
        </p>
        <div className="w-full flex justify-between">
          <Link to="/exercises">
            <UploadBtn className="">К упражнениям</UploadBtn>
          </Link>
          <UploadBtn
            onClick={() => {
              setState("setup");
            }}
          >
            Пройти снова
          </UploadBtn>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-200 h-screen w-full flex items-center justify-center">
      {content}
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>
    </div>
  );
};

export default SoundStairsPage;
