import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import Audio from "./../../../models/Audio";
import Question from "../../../models/Question";

interface AudiosState {
  audios: Audio[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AudiosState = {
  audios: [],
  status: "idle",
  error: null,
};

const audiosSlice = createSlice({
  name: "audios",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAsync.pending, (state) => {
        state.status = "loading";
        console.log("Pending audios...");
      })
      .addCase(getAsync.fulfilled, (state, action: PayloadAction<Audio[]>) => {
        state.audios = action.payload;
        state.status = "succeeded";
        console.log("Audios loaded");
      })
      .addCase(getAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message!;
        console.log("Audios fetching failed");
      });
    builder
      .addCase(createAsync.pending, (state) => {
        state.status = "loading";
        console.log("Audio creating...");
      })
      .addCase(createAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.audios = [...state.audios, action.payload];
        state.status = "succeeded";
        console.log("Audio created");
      })
      .addCase(createAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message!;
        console.log("Audio creation failed");
      });
    builder
      .addCase(deleteAsync.pending, (state) => {
        state.status = "loading";
        console.log("Deleting audio...");
      })
      .addCase(
        deleteAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          const deletedId = action.payload;
          console.log("deletedId", deletedId);
          console.log("before state.audios", state.audios);
          state.audios = state.audios.filter((audio) => audio.id !== deletedId);
          console.log("state.audios", state.audios);
          state.status = "succeeded";
          console.log("Audio deleted");
        }
      )
      .addCase(deleteAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message!;
        console.log("Audio deletion failed");
      });
    // builder
    //   .addCase(updateAsync.pending, (state) => {
    //     state.status = "loading";
    //     console.log("Audio creating...");
    //   })
    //   .addCase(updateAsync.fulfilled, (state, action: PayloadAction<any>) => {
    //     state.audios = [...state.audios, action.payload];
    //     state.status = "succeeded";
    //     console.log("Audio created");
    //   })
    //   .addCase(updateAsync.rejected, (state, action) => {
    //     state.status = "failed";
    //     state.error = action.error.message!;
    //     console.log("Audio creation failed");
    //   });
  },
});

export const getAsync = createAsyncThunk("audios/getAsync", async () => {
  console.log("Start loading audios...");
  const requestOptions: RequestInit = {
    method: "GET",
    credentials: "include",
  };

  try {
    const response = await fetch("/api/v1/audios", requestOptions);
    if (response.ok) {
      const data = await response.json();
      return data.audios;
    } else {
      console.error("Failed to fetch audio list");
    }
  } catch (error) {
    console.error("Error fetching audio list:", error);
  }
});

export const createAsync = createAsyncThunk(
  "audios/createAsync",
  async ({
    file,
    type,
    questions,
  }: {
    file: any;
    type: string;
    questions?: Question[];
  }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    if (type === "interview") {
      const jsonQuestions = JSON.stringify(questions);
      formData.append("questions", jsonQuestions);
    }

    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
      body: formData,
    };

    try {
      const response = await fetch("/api/v1/audios", requestOptions);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        console.error("Failed to fetch audio list");
      }
    } catch (error) {
      console.error("Error fetching audio list:", error);
    }
  }
);

export const deleteAsync = createAsyncThunk(
  "audios/deleteAsync",
  async (id: number) => {
    const requestOptions: RequestInit = {
      method: "DELETE",
      credentials: "include",
    };

    try {
      const response = await fetch(`/api/v1/audios/${id}`, requestOptions);
      if (response.ok) {
        return id;
      } else {
        console.error("Failed to delete audio");
      }
    } catch (error) {
      console.error("Error deleting audio:", error);
    }
  }
);

// export const updateAsync = createAsyncThunk(
//   "audios/updateAsync",
//   async ({ id, newName }: { id: number; newName: string }) => {
//     const requestOptions: RequestInit = {
//       method: "PATCH",
//       credentials: "include",
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify({
//         title: newName,
//       }),
//     };

//     try {
//       // sleep(5000);
//       const response = await fetch(`/api/v1/audios/${id}`, requestOptions);
//       if (response.ok) {
//         const data = await response.json();
//         return data;
//       } else {
//         console.error("Failed to fetch audio list");
//       }
//     } catch (error) {
//       console.error("Error fetching audio list:", error);
//     }
//   }
// );

export default audiosSlice.reducer;
