import { PropsWithChildren } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../services/state/store";
import { getByTokenAsync } from "../services/state/user/userSlice";

type AuthProviderProps = PropsWithChildren;

export default function AuthProvider({ children }: AuthProviderProps) {
  console.log("AuthProvider: render");

  const dispatch = useDispatch<AppDispatch>();
  dispatch(getByTokenAsync());

  return <>{children}</>;
}
