import { Link } from "react-router-dom";

const MaterialCard: React.FC<{
  name: string;
  link: string;
  icon_link: string;
  description: string;
}> = ({ name, link, icon_link, description }) => {
  return (
    <Link
      to={link}
      className="bg-purple-100 hover:bg-purple-300 h-28 py-4 px-4 rounded-xl flex items-start justify-start"
    >
      <img src={icon_link} alt="" className="h-full mr-2 rounded-md" />
      <div className="flex flex-col items-start">
        <h2 className="text-lg font-medium">{name}</h2>
        <p className="line-clamp-2">{description}</p>
      </div>
    </Link>
  );
};

export default MaterialCard;
