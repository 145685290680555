import { useNavigate } from "react-router-dom";

import { RootState } from "../services/state/store";
import { useSelector } from "react-redux";
import Spinner from "./ui/Spinner";
import { useEffect } from "react";

const ProtectedRoute: React.FC<{
  children: any;
}> = ({ children }) => {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user.user);
  const userStatus = useSelector((state: RootState) => state.user.status);
  // const error = useSelector((state: RootState) => state.user.error)

  useEffect(() => {
    console.log("ProtectedRoute: useEffect:", user);
    if (userStatus !== "loading" && user === null) {
      console.log("ProtectedRoute: useEffect: redirect to SignIn");
      navigate("/signin");
      return;
    }
  }, [navigate, user, userStatus]);

  let content;
  console.log("ProtectedRoute", "status", userStatus, "user", user);
  if (userStatus === "loading" || userStatus === "failed" || user === null) {
    content = (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  } else if (userStatus === "succeeded" && user !== null) {
    content = children;
  } else {
    content = (
      <div className="flex items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  return content;
};

export default ProtectedRoute;
