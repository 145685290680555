import { useNavigate } from "react-router-dom";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import BackBtn from "../exercises/BackBtn";

const SpeechSpeedPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-purple-200 h-full w-full px-56 py-8 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>

      <div className="bg-white w-full shadow-md rounded-2xl px-20 pt-8 pb-8 flex flex-col items-center text-xl">
        <h1 className="text-purple-800 w-full text-5xl mb-4">
          Скорость речи: важнейший аспект эффективной коммуникации
        </h1>
        <img src="/assets/speech_speed.png" alt="" className="mb-4" />
        <p className="mb-4">
          Скорость речи играет значительную роль в процессе общения, влияя на понимание, вовлеченность собеседников и
          эффективность передачи информации. Нормальная скорость речи обычно составляет от 80 до 120 слов в минуту.
          Поддержание оптимального темпа речи имеет множество преимуществ как в повседневном общении, так и в
          профессиональной деятельности.
        </p>

        <h2 className="w-full text-3xl mb-4">Значение оптимальной скорости речи:</h2>

        <ul className="space-y-4 pl-2 mb-4">
          <li>
            1. Ясность и понимание: Слишком быстрая речь может затруднить понимание информации, особенно для аудитории,
            которая не успевает усвоить содержание сообщения. С другой стороны, чрезмерно медленная речь может вызвать
            скученность и потерю интереса со стороны слушателей. Поддержание оптимальной скорости помогает добиться
            ясного и полного понимания.
          </li>
          <li>
            2. Вовлеченность аудитории: Правильно подобранная скорость речи способствует удержанию внимания аудитории.
            Это позволяет сделать выступление более привлекательным и интересным для слушателей, помогая им легче
            усваивать информацию.
          </li>
          <li>
            3. Эмоциональная составляющая: Скорость речи также может влиять на эмоциональное воздействие выступающего.
            Паузы и акценты, соблюдение определенного ритма помогают подчеркнуть важные моменты выступления, создавая
            эмоциональную связь с аудиторией.
          </li>
        </ul>

        <h2 className="w-full text-3xl mb-4">Рекомендации для поддержания оптимальной скорости речи:</h2>

        <ul className="space-y-4 pl-2 mb-4">
          <li>1. Измерьте свою скорость речи: Используйте наше приложение, чтобы определить свою текущую скорость.</li>
          <li>
            2. Упражняйте медленное чтение: Практикуйте чтение текстов вслух медленно и вдумчиво, уделяя внимание
            четкости произнесения каждого слова.
          </li>
          <li>
            3. Говорите вслух: Попробуйте говорить вслух с самим собой или близким другом, обращая внимание на скорость
            речи и по возможности корректируя ее.
          </li>
          <li>
            4. Следите за дыханием: Правильное дыхание помогает поддерживать постоянную скорость речи. Сосредоточьтесь
            на спокойном и глубоком дыхании во время разговора.
          </li>
          <li>
            5. Избавьтесь от лишних слов: Избегайте использования слов-паразитов, таких как "эм", "э" и "ну", которые
            могут замедлять вашу речь.
          </li>
          <li>
            6. Будьте осознанны: Обращайте внимание на сигналы от слушателей, такие как выражения лиц или язык тела,
            которые могут указывать на то, что вы говорите слишком быстро или медленно.
          </li>
        </ul>

        <UploadBtn className="" onClick={() => navigate(-1)}>
          Вернуться
        </UploadBtn>
      </div>
    </div>
  );
};

export default SpeechSpeedPage;
