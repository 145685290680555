import { useEffect, useState } from "react";

interface CircleTimerProps {
  totalTime: number; // seconds
  onComplete: () => void;
  className?: any;
  children?: any;
}

const CircleTimer: React.FC<CircleTimerProps> = ({
  totalTime,
  onComplete,
  className,
  children,
}) => {
  const [curTime, setCurTime] = useState(totalTime);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurTime((prevCurTime) => {
        return prevCurTime - 1;
      });
    }, 1000); // milliseconds

    return () => clearInterval(interval);
  }, [totalTime]);

  useEffect(() => {
    if (curTime <= 0) {
      onComplete();
      // setCurTime(totalTime);
    }
  }, [curTime, onComplete]);

  return (
    <div
      className={`bg-white w-20 h-20 rounded-full flex justify-center items-center text-purple-900 text-5xl font-bold ${className}`}
    >
      {curTime}
    </div>
  );
};

export default CircleTimer;
