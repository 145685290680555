import { useState } from "react";

import UploadBtn from "../../components/ui/buttons/UploadBtn";
import { Link } from "react-router-dom";
import BackBtn from "./BackBtn";
import { getRandomWords } from "../../utils/random";

const MIN_N_WORDS = 3;
const MAX_N_WORDS = 10;
const DEFAULT_N_WORDS = 5;

const instructions = [
  "Сядьте поближе к краешку стула.",
  "Приподнимите грудь.",
  "Подтяните живот.",
  "Голову держите ровно.",
  "Поднимите язык к нёбу и произнесите протяжное «рррр».",
  "Повторяйте слова, растягивая букву «р».",
];

const words = [
  "тигр",
  "ромашка",
  "рык",
  "рев",
  "грамм",
  "графин",
  "крокодил",
  "рыба",
  "раз",
  "рана",
  "радио",
  "радость",
  "раковина",
  "жара",
  "нора",
  "дыра",
  "гора",
  "буран",
  "гараж",
  "жираф",
  "парад",
  "роза",
  "роща",
  "род",
  "рожки",
  "розовый",
  "ровный",
  "мороз",
  "народ",
  "паром",
  "ворона",
  "корова",
  "короткий",
  "строка",
  "коробка",
  "пирог",
  "герой",
  "урок",
  "гора",
  "ручка",
  "рубка",
  "русый",
  "рука",
  "беру",
  "орудие",
];

const GrowlPage = () => {
  const [numWords, setNumWords] = useState(DEFAULT_N_WORDS);
  const [state, setState] = useState<"setup" | "prepare" | "started" | "finished">("setup");

  const handleNumPromptsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    setNumWords(newValue);
  };

  let content;
  if (state === "setup") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-4 text-2xl text-center">Рычание</h1>
        <div className="flex items-start justify-between mb-4">
          <p className="font-bold text-xl text-purple-500">Инструкция</p>
          <UploadBtn
            onClick={() => {
              setState("prepare");
            }}
          >
            Начать
          </UploadBtn>
        </div>

        <div className="mb-4">
          <ul>
            {instructions.map((instruction) => (
              <li key={instruction}>
                <span>•</span> {instruction}
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-4">
          <div className="flex justify-between">
            <label htmlFor="n-prompts">Установите количество слов</label>
            <p className="font-bold text-xl text-purple-500">{numWords}</p>
          </div>
          <input
            id="n-prompts"
            type="range"
            min={MIN_N_WORDS}
            max={MAX_N_WORDS}
            value={numWords}
            onChange={handleNumPromptsChange}
            className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
          />
          <div className="flex justify-between">
            <p>{MIN_N_WORDS}</p>
            <p>{MAX_N_WORDS}</p>
          </div>
        </div>
      </div>
    );
  } else if (state === "prepare") {
    content = (
      <div className="bg-white w-1/2 p-10 rounded-xl flex flex-col gap-8 items-center">
        <p className="font-bold text-3xl text-center">Поднимите язык к нёбу и произнесите протяжное «рррр»</p>

        <UploadBtn onClick={() => setState("started")}>Дальше</UploadBtn>
      </div>
    );
  } else if (state === "started") {
    content = (
      <div className="bg-white w-1/2 p-10 rounded-xl flex flex-col gap-8 items-center">
        <p className="font-bold text-3xl text-center">{getRandomWords(words, numWords).join(", ")}</p>

        <UploadBtn onClick={() => setState("finished")}>Дальше</UploadBtn>
      </div>
    );
  } else if (state === "finished") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-6 text-purple-900 font-bold text-2xl text-center">Вы выполнили упражнение Рычание!</h1>
        <p className="text-center mb-4">Рычание не только придаст вашему голосу силу, но и поможет улучшить дикцию.</p>
        <div className="flex justify-between">
          <Link to="/exercises">
            <UploadBtn className="">К упражнениям</UploadBtn>
          </Link>
          <UploadBtn
            onClick={() => {
              setState("setup");
            }}
          >
            Пройти снова
          </UploadBtn>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-200 h-screen w-full flex items-center justify-center">
      {content}
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>
    </div>
  );
};

export default GrowlPage;
