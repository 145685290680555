import SignUpForm from "./SignUpForm";

const SignUpPage = () => {
  return (
    <div className="bg-purple-200 h-screen w-screen flex justify-center items-center">
      <SignUpForm />
    </div>
  );
};

export default SignUpPage;
