import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../services/state/store";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import Table from "../../components/Table";
import FileUploadModal from "../../components/FileUploadModal";
import { getAsync } from "../../services/state/audios/audiosSlice";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import Spinner from "../../components/ui/Spinner";
import { useLocation } from "react-router-dom";
import { reset } from "../../services/state/question/questionSlice";

const SpeechesPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const audios = useSelector((state: RootState) => state.audios.audios);
  const audiosStatus = useSelector((state: RootState) => state.audios.status);
  const error = useSelector((state: RootState) => state.audios.error);

  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  useEffect(() => {
    console.log("SpeechesPage: useEffect1");
    if (audiosStatus === "idle") {
      dispatch(getAsync());
    }
  }, [dispatch, audiosStatus]);

  useEffect(() => {
    console.log("SpeechesPage: useEffect2");
    dispatch(reset());
    if (audiosStatus !== "loading") {
      dispatch(getAsync());
    }
  }, [dispatch, location.pathname]);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  let content;
  console.log("SpeechesPage: status =", audiosStatus);
  if (audiosStatus === "loading") {
    content = (
      <div className="flex flex-col gap-2">
        <Spinner/>
        Анализируем вашу речь...
      </div>
    );
  } else if (audiosStatus === "succeeded") {
    content = <Table data={audios} />;
  } else if (audiosStatus === "failed") {
    content = <div>{error}</div>;
  }

  return (
    <div className="bg-white h-screen w-full flex">
      <LeftSidebar></LeftSidebar>
      <div className="w-full ml-56 pt-6 px-8 ">
        <h1 className="text-xl mb-4">Мои записи</h1>
        <UploadBtn className="h-10 mb-4" onClick={openModal}>
          Загрузить
        </UploadBtn>
        <div className="w-full">{content}</div>
        {isModalOpen && <FileUploadModal onClose={closeModal} />}
      </div>
    </div>
  );
};

export default SpeechesPage;
