import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import MaterialCard from "./MaterialCard";

const meterials = [
  {
    name: "Как избавиться от привычки использовать словесный мусор в речи",
    link: "/materials/filler-words",
    icon_link: "/assets/filler_words_1.png",
    description:
      'Междометия такие как "ээ", "эм" и "аа" являются частями речи, которые выражают чувства, эмоции или отношение к высказываемой информации. Они могут использоваться для усиления выражения или в качестве звукового заполнителя в разговорной речи.',
  },
  {
    name: "Почему важно находиться в центре кадра во время онлайн-собеседований",
    link: "/materials/centering",
    icon_link: "/assets/centering_1.png",
    description:
      "В век дистанционного взаимодействия и онлайн-собеседований, правильная постановка камеры и положение в кадре играют важную роль в формировании впечатлений и успешном прохождении собеседования. В этой статье мы рассмотрим пять ключевых преимуществ нахождения в центре кадра во время онлайн-собеседования.",
  },
  {
    name: "Скорость речи: важнейший аспект эффективной коммуникации",
    link: "/materials/speech-speed",
    icon_link: "/assets/speech_speed.png",
    description:
      "Скорость речи играет значительную роль в процессе общения, влияя на понимание, вовлеченность собеседников и эффективность передачи информации. Нормальная скорость речи обычно составляет от 80 до 120 слов в минуту.",
  },
  {
    name: "Важность зрительного контакта: создание эмоциональной связи с собеседником",
    link: "/materials/eye-contact",
    icon_link: "/assets/eye_contact.png",
    description:
      "Зрительный контакт является одним из ключевых аспектов успешной коммуникации, важным как в реальном мире, так и в онлайн-взаимодействиях. В данной статье мы рассмотрим значимость зрительного контакта и его влияние на эффективность общения.",
  },
];

const MaterialsPage = () => {
  return (
    <div className="bg-white w-full flex">
      <LeftSidebar></LeftSidebar>
      <div className="w-full ml-56 pt-6 px-8 pb-6">
        <h1 className="w-full text-xl mb-4">Материалы</h1>
        <div className="flex flex-col justify-start gap-y-4">
          {meterials.map((material) => (
            <MaterialCard
              name={material.name}
              link={material.link}
              icon_link={material.icon_link}
              description={material.description}
            ></MaterialCard>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MaterialsPage;
