import SignInForm from "./SignInForm";

const SignInPage = () => {
  return (
    <div className="bg-purple-200 h-screen w-screen flex justify-center items-center">
      <SignInForm />
    </div>
  );
};

export default SignInPage;
