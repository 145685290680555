import { useNavigate } from "react-router-dom";
import UploadBtn from "../../components/ui/buttons/UploadBtn";
import BackBtn from "../exercises/BackBtn";

const EyeContactPage = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-purple-200 h-full w-full px-56 py-8 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>

      <div className="bg-white w-full shadow-md rounded-2xl px-20 pt-8 pb-8 flex flex-col items-center text-xl">
        <h1 className="text-purple-800 w-full text-5xl mb-4">
          Важность зрительного контакта: создание эмоциональной связи с собеседником
        </h1>
        <img src="/assets/eye_contact.png" alt="" className="mb-4" />
        <p className="mb-4">
          Зрительный контакт является одним из ключевых аспектов успешной коммуникации, важным как в реальном мире, так
          и в онлайн-взаимодействиях. В данной статье мы рассмотрим значимость зрительного контакта и его влияние на
          эффективность общения.
        </p>

        <h2 className="w-full text-3xl mb-4">Значение зрительного контакта в коммуникации</h2>

        <p className="mb-4">
          <span className="font-medium">Зрительный контакт</span> — это способность устанавливать и поддерживать
          визуальный контакт с собеседником во время разговора. Он играет важную роль в формировании взаимоотношений,
          передаче информации и понимании эмоций. Вот почему он так важен:
        </p>

        <ul className="space-y-4 pl-2 mb-4">
          <li>
            1. Установление связи и доверия: Зрительный контакт помогает установить эмоциональную связь с собеседником и
            создать атмосферу доверия. Он передает сигналы о вашем внимании, заинтересованности и уважении к
            собеседнику.
          </li>
          <li>
            2. Понимание эмоций и намерений: Через зрительный контакт вы можете лучше понять эмоциональное состояние
            собеседника, его настроение и намерения. Это помогает корректно интерпретировать информацию и реагировать
            адекватно.
          </li>
          <li>
            3. Улучшение качества общения: Зрительный контакт способствует более глубокому и эффективному общению. Он
            помогает избежать недопониманий, уточнить информацию и поддержать диалог на нужном уровне.
          </li>
          <li>
            4. Поддержание внимания: Через зрительный контакт вы можете удерживать внимание собеседника, делая общение
            более интересным и привлекательным.
          </li>
        </ul>

        <UploadBtn className="" onClick={() => navigate(-1)}>
          Вернуться
        </UploadBtn>
      </div>
    </div>
  );
};

export default EyeContactPage;
