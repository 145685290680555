import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Message from "../../../models/Message";
import { RootState } from "../store";

const GEN_QUESTION_URL = "/api/v1/gen_question";

interface QuestionsState {
  all: string[];
  selectedIdxs: number[];
  remainedIdxs: number[];
  selectedQuestions: string[];
  curQuestionIdx: number;
  messages: Message[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: QuestionsState = {
  all: [
    "Расскажите о себе.",
    "Почему вы хотите оставить свою нынешнюю должность?",
    "Расскажите о случае, когда вы проявили лидерство.",
    "Расскажите о случае, когда вы добились успеха в команде.",
    "Опишите свой самый сложный проект.",
    "Опишите достижение, которым вы гордитесь.",
    "Опишите свой стиль руководства.",
    "Опишите случай, когда вы допустили ошибку.",
    "Опишите случай, когда вам пришлось кого-то уговаривать.",
    "Опишите случай, когда вы превзошли ожидания людей.",
  ],
  selectedIdxs: [0, 1, 2],
  remainedIdxs: [3, 4, 5, 6, 7, 8, 9],
  selectedQuestions: [],
  curQuestionIdx: 0,
  messages: [],
  status: "idle",
  error: null,
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    reset: (state) => {
      state.all = initialState.all;
      state.selectedIdxs = initialState.selectedIdxs;
      state.remainedIdxs = initialState.remainedIdxs;
      state.selectedQuestions = initialState.selectedQuestions;
      state.curQuestionIdx = initialState.curQuestionIdx;
      state.messages = initialState.messages;
      state.status = initialState.status;
      state.error = initialState.error;
      console.log("state reseted");
    },
    select: (state, action: PayloadAction<number>) => {
      // remove
      const remainIdx = state.remainedIdxs.indexOf(action.payload);
      if (remainIdx !== -1) {
        state.remainedIdxs.splice(remainIdx, 1);
      }

      // insert
      let insertIndex = state.selectedIdxs.findIndex((element) => element > action.payload);

      if (insertIndex === -1) {
        insertIndex = state.selectedIdxs.length;
      }

      state.selectedIdxs.splice(insertIndex, 0, action.payload);
    },
    remove: (state, action: PayloadAction<number>) => {
      // remove
      const selectedIdx = state.selectedIdxs.indexOf(action.payload);
      if (selectedIdx !== -1) {
        state.selectedIdxs.splice(selectedIdx, 1);
      }

      // insert
      let insertIndex = state.remainedIdxs.findIndex((element) => element > action.payload);
      if (insertIndex === -1) {
        insertIndex = state.remainedIdxs.length;
      }
      state.remainedIdxs.splice(insertIndex, 0, action.payload);
    },
    setQuestions: (state) => {
      state.selectedIdxs.forEach((selectedIdx) => {
        state.selectedQuestions.push(state.all[selectedIdx]);
      });
      state.messages.push({
        role: "assistant",
        content: state.selectedQuestions[0],
      });
    },
    nextQuestion: (state) => {
      state.curQuestionIdx += 1;
    },
    insert: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(insertAnswerAsync.pending, (state) => {
        state.status = "loading";
        console.log("insertAnswerAsync: pending");
      })
      .addCase(insertAnswerAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.messages.push({
          role: "user",
          content: action.payload,
        });
        state.status = "succeeded";
        console.log("insertAnswerAsync: succeeded, text= ", action.payload);
      })
      .addCase(insertAnswerAsync.rejected, (state, action) => {
        state.error = action.error.message!;
        state.status = "failed";
        console.log("insertAnswerAsync: failed");
      });
    builder
      .addCase(genQuestionAsync.pending, (state) => {
        state.status = "loading";
        console.log("genQuestionAsync: pending");
      })
      .addCase(genQuestionAsync.fulfilled, (state, action: PayloadAction<string>) => {
        state.messages.push({
          role: "assistant",
          content: action.payload,
        });

        state.selectedQuestions.splice(state.curQuestionIdx, 0, action.payload);

        state.status = "succeeded";
        console.log("genQuestionAsync: succeeded");
        console.log("genQuestionAsync: state.selectedQuestions.length=", state.selectedQuestions.length);
      })
      .addCase(genQuestionAsync.rejected, (state, action) => {
        state.error = action.error.message!;
        state.status = "failed";
        console.log("genQuestionAsync: failed");
      });
  },
});

export const insertAnswerAsync = createAsyncThunk("question/insertAnswerAsync", async ({ file }: { file: File }) => {
  console.log("Start speech to text...");

  const formData = new FormData();
  formData.append("file", file);

  const requestOptions: RequestInit = {
    method: "POST",
    credentials: "include",
    body: formData,
  };

  try {
    const response = await fetch("/api/v1/speech2text", requestOptions);
    if (response.ok) {
      const fetchData = await response.json();
      return fetchData.text;
    } else {
      console.error("Failed to fetch audio list");
    }
  } catch (error) {
    console.error("Error fetching audio list:", error);
  }
});

export const genQuestionAsync = createAsyncThunk(
  "question/genQuestionAsync",
  async ({ messages }: { messages: Message[] }, thunkAPI) => {
    console.log("Start gen question...");

    const state = thunkAPI.getState() as RootState;

    const msgs = state.question.messages;

    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(msgs),
    };

    try {
      const response = await fetch(GEN_QUESTION_URL, requestOptions);
      if (response.ok) {
        const data = await response.json();
        return data.text;
      } else {
        console.error("Failed to gen question");
        return "";
      }
    } catch (err) {
      console.error("Error gen question:", err);
      return "";
    }
  }
);

export const { reset, select, remove, insert, setQuestions, nextQuestion } = questionSlice.actions;

export default questionSlice.reducer;
