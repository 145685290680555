import React, { useState, useEffect } from "react";

const MIN_VOLUME = 60;
const MAX_VOLUME = 130;

const MicrophoneIndicator: React.FC = () => {
  const [isSpeaking, setIsSpeaking] = useState<boolean>(false);
  const [avg, setAvg] = useState<number>(0);
  const [middle, setMiddle] = useState<number>(0);
  const [side, setSide] = useState<number>(0);

  useEffect(() => {
    let audioContext: AudioContext | null = null;
    let analyser: AnalyserNode | null = null;

    const handleAudio = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new AudioContext();
        analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        analyser.fftSize = 32;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const updateVisualization = () => {
          analyser?.getByteFrequencyData(dataArray);
          let average = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
          setAvg(average);
          let volumeRatio = (average - MIN_VOLUME) / (MAX_VOLUME - MIN_VOLUME);
          if (volumeRatio > 1) {
            volumeRatio = 1;
          }
          if (volumeRatio < 0) {
            volumeRatio = 0;
          }
          setMiddle(2 + 18 * volumeRatio);
          setSide(2 + 8 * volumeRatio);
        //   console.log("avg: ", average, "middle:", 2 + 18 * volumeRatio, "side:", 2 + 8 * volumeRatio);
          setIsSpeaking(average > 80);
          requestAnimationFrame(updateVisualization);
        };

        updateVisualization();
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    };

    handleAudio();

    return () => {
      if (audioContext) audioContext.close();
    };
  }, []);

  return (
    <div className="bg-gray-300 h-8 px-2 py-1 flex gap-2 items-center justify-center rounded">
      <div className={`bg-black w-1 rounded`} style={{ height: `${side}px` }} />
      <div className={`bg-black w-1 rounded`} style={{ height: `${middle}px` }} />
      <div className={`bg-black w-1 rounded`} style={{ height: `${side}px` }} />
    </div>
  );
};

export default MicrophoneIndicator;
