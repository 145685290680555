import { useCallback, useEffect, useRef, useState } from "react";

import UploadBtn from "../../components/ui/buttons/UploadBtn";
import DangerBtn from "../../components/ui/buttons/DangerBtn";
import SuccessBtn from "../../components/ui/buttons/SuccessBtn";
import PrimaryBtn from "../../components/ui/buttons/PrimaryBtn";
import Webcam from "react-webcam";
import CircleTimer from "../InterviewPage/CountCircle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../services/state/store";
import { convertAsync } from "../../services/state/tongueTwister/tongueTwisterSlice";
import { Link } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import BackBtn from "./BackBtn";

const MIME_TYPE = "video/webm";
const FILE_NAME = "new_speech.webm";
const TIMER_SECONDS = 3;
// const TIMER_SECONDS = 1;

function formatTimeFromSeconds(totalSeconds: number): string {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedDate = `${String(minutes).padStart(2, "0")}:${String(
    seconds
  ).padStart(2, "0")}`;
  return formattedDate;
}

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
};

const instructions = [
  "Сядьте поближе к краешку стула.",
  "Приподнимите грудь.",
  "Подтяните живот.",
  "Голову держите ровно.",
  "Проговорите скороговорку медленно, чётко выговаривая каждое слово.",
  "Проговорите скороговорку в удобном темпе, но шёпотом, но так чтобы звуки были слышны и понятны.",
  "Постарайтесь проговорить скороговорку в быстром темпе.",
];

// const phrase = "У осы не усы, не усища, а усики.";
const phrase = "На дворе трава, на траве дрова.";

const playInstructions = [
  "Проговорите скороговорку медленно, чётко выговаривая каждое слово.",
  "Проговорите скороговорку в удобном темпе, но шёпотом, но так чтобы звуки были слышны и понятны.",
  "Постарайтесь проговорить скороговорку в быстром темпе.",
];

const TongueTwistersPage = () => {
  // Redux
  const tongueTwister = useSelector((state: RootState) => state.tongueTwister);
  const dispatch = useDispatch<AppDispatch>();

  const [curInstrIdx, setCurInstrIdx] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [dataHandled, setDataHandled] = useState(true);

  const [recStarted, setRecStarted] = useState(false);

  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);

  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [isTimerActivated, setIsTimerActivated] = useState(false);

  const [state, setState] = useState<"setup" | "started" | "finished">("setup");
  const [complexity, setComplexity] = useState<
    "basic" | "standart" | "professional"
  >("basic");

  console.log("RERENDER!!!");
  const [curSpeed, setCurSpeed] = useState<"slow" | "whisper" | "quick">(
    "slow"
  );

  function onNextQuestion() {
    console.log(
      "Next Question: speed=",
      curSpeed,
      "curInstrIdx = ",
      curInstrIdx
    );

    stopCapture();
    setCurInstrIdx((prev) => prev + 1);
    setDataHandled(false);
  }

  useEffect(() => {
    let timerId: NodeJS.Timer;

    const tick = () => {
      if (recStarted) {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }
    };

    timerId = setInterval(tick, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [recStarted]);

  function initRecorder() {
    console.log("initRecorder: webcamRef.current", webcamRef.current); // null
    console.log(
      "initRecorder: webcamRef.current.stream",
      webcamRef.current?.stream
    );
    // console.log("stream", stream);
    if (webcamRef.current && webcamRef.current.stream) {
      let recordOptions: MediaRecorderOptions = {
        mimeType: MIME_TYPE,
      };

      mediaRecorderRef.current = new MediaRecorder(
        webcamRef.current.stream!,
        // stream,
        recordOptions
      );

      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      console.log("Recorder inited");
    }
  }

  const handleDataAvailable = ({ data }: { data: Blob }) => {
    // sleep(1000);
    console.log(
      "Next handleDataAvailable: speed=",
      curSpeed,
      "curInstrIdx = ",
      curInstrIdx
    );

    console.log("DATA AVAILABLE");
    console.log("Type of data: ", typeof data);
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }

    if (data.size > 0) {
      // const blob = new Blob([data], {
      //   type: MIME_TYPE,
      // });
      const file = new File([data], FILE_NAME, { type: MIME_TYPE });
      dispatch(
        convertAsync({
          file: file,
          speed: curSpeed,
        })
      );
      setRecordedChunks([]);
    } else {
      console.warn("No chunks!");
    }

    if (curSpeed === "slow") {
      console.log("to whisper");
      setCurSpeed("whisper");
    } else if (curSpeed === "whisper") {
      console.log("to quick");
      setCurSpeed("quick");
      setIsLastQuestion(true);
    } else {
      console.log("FINISH");
      setState("finished");
      return;
    }

    setIsTimerActivated(true);
    console.log("Timer started");

    setDataHandled(true);
  };

  const startCapture = useCallback(() => {
    mediaRecorderRef.current?.start();
    console.log("Capturing started");
    setRecStarted(true);
  }, [mediaRecorderRef]);

  const stopCapture = useCallback(() => {
    mediaRecorderRef.current?.stop();
    console.log("Capturing stoped");
    setRecStarted(false);
  }, [mediaRecorderRef]);

  function onTimerComplete() {
    console.log("Timer completed");
    setIsTimerActivated(false);

    initRecorder();
    startCapture();
  }

  function onDone() {
    console.log("Done: speed=", curSpeed);

    stopCapture();

    if (curSpeed === "slow") {
      setCurSpeed("whisper");
    } else if (curSpeed === "whisper") {
      setCurSpeed("quick");
      setIsLastQuestion(true);
    } else {
      console.log("FINISH");
    }

    setDataHandled(false);

    // handleDone();
  }

  let content;
  if (state === "setup") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-4 text-2xl text-center">Скороговорки</h1>
        <div className="flex items-start justify-between mb-4">
          <p className="font-bold text-xl text-purple-500">Инструкция</p>
          <UploadBtn
            onClick={() => {
              setState("started");
              setIsTimerActivated(true);
            }}
          >
            Начать
          </UploadBtn>
        </div>

        <div className="mb-4">
          <ul>
            {instructions.map((instruction) => (
              <li key={instruction}>
                <span>•</span> {instruction}
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-4 flex items-center justify-between">
          <p className="font-medium text-lg">Выберите сложность</p>
          <div className="flex gap-3">
            <SuccessBtn
              className={`w-32 ${
                complexity === "basic" && "border-4 border-black"
              }`}
              onClick={() => {
                setComplexity("basic");
              }}
            >
              Basic
            </SuccessBtn>
            <PrimaryBtn
              className={`w-32 ${
                complexity === "standart" && "border-4 border-black"
              }`}
              onClick={() => {
                setComplexity("standart");
              }}
            >
              Standart
            </PrimaryBtn>
            <DangerBtn
              className={`w-32 ${
                complexity === "professional" && "border-4 border-black"
              }`}
              onClick={() => {
                setComplexity("professional");
              }}
            >
              Professional
            </DangerBtn>
          </div>
        </div>
      </div>
    );
  } else if (state === "started") {
    if (dataHandled) {
      content = (
        <div className="bg-white p-8 rounded-xl flex flex-col items-center">
          <p className="font-bold text-xl text-purple-500">
            {playInstructions[curInstrIdx]}
          </p>

          <p className="font-bold text-3xl text-center w-96 mt-10">{phrase}</p>

          <div className="flex h-20 mt-10 flex-col items-center">
            <div className="flex justify-center items-center">
              <Webcam
                width={0}
                height={0}
                videoConstraints={false}
                mirrored={true}
                audioConstraints={audioConstraints}
                audio={true}
                muted={true}
                ref={webcamRef}
              />
              {isTimerActivated && (
                <CircleTimer
                  totalTime={TIMER_SECONDS}
                  onComplete={onTimerComplete}
                />
              )}
              {!isTimerActivated && (
                <div className="bg-gray-200 px-4 py-3 rounded-2xl flex gap-2 items-center">
                  <p className="font-bold text-lg">
                    {formatTimeFromSeconds(seconds)}
                  </p>
                  {/* <UploadBtn className="h-10" onClick={onDone}>
                  Завершить
                </UploadBtn> */}

                  {isLastQuestion ? (
                    <UploadBtn className="h-10" onClick={onDone}>
                      Завершить
                    </UploadBtn>
                  ) : (
                    <UploadBtn className="h-10" onClick={onNextQuestion}>
                      Закончить
                    </UploadBtn>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      content = <Spinner />;
    }
  } else if (state === "finished") {
    content = (
      <div className="bg-white w-160 p-8 rounded-xl flex flex-col">
        <h1 className="mb-6 text-purple-900 font-bold text-2xl text-center">
          Вы выполнили упражнение Скороговорки!
        </h1>
        <div className="flex items-center mb-4">
          <p className="w-24 mr-4">Медленно</p>
          <div className="bg-gray-200 w-full p-5 rounded-2xl">
            <p>{tongueTwister.slow}</p>
          </div>
        </div>
        <div className="flex items-center mb-4">
          <p className="w-24 mr-4">Шёпотом</p>
          <div className="bg-gray-200 w-full p-5 rounded-2xl">
            <p>{tongueTwister.whisper}</p>
          </div>
        </div>
        <div className="flex items-center mb-4 ">
          <p className="w-24 mr-4">Быстро</p>
          <div className="bg-gray-200 w-full p-5 rounded-2xl">
            <p>{tongueTwister.quick}</p>
          </div>
        </div>
        <p className="text-center mb-4">
          Скороговорки необходимы для развития речи, чувства ритма, формированию
          правильного звукопроизношения и стимулирует развитие слухового
          восприятия речи.
        </p>
        <div className="flex justify-between">
          <Link to="/exercises">
            <UploadBtn className="">К упражнениям</UploadBtn>
          </Link>
          <UploadBtn
            onClick={() => {
              setState("setup");
            }}
          >
            Пройти снова
          </UploadBtn>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-200 h-screen w-full flex items-center justify-center">
      {content}
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>
    </div>
  );
};

export default TongueTwistersPage;
