import React, { useEffect } from "react";
import { useRef, useState, useCallback } from "react";
import Webcam from "react-webcam";
import UploadBtn from "./ui/buttons/UploadBtn";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../services/state/store";
import { createAsync } from "../services/state/audios/audiosSlice";
import { useNavigate } from "react-router-dom";
import MicrophoneIndicator from "./MicrophoneIndicator";

const MIME_TYPE = "video/webm";
const FILE_NAME = "new_speech.webm";

const videoConstraints = {
  // aspectRatio: 0.6666666667,
  facingMode: "user",
  width: { min: 640 },
  height: { min: 480 },
};

// https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/mimeType

const audioConstraints = {
  suppressLocalAudioPlayback: true,
  noiseSuppression: true,
  echoCancellation: true,
};

const WebcamVideo: React.FC<{
  onStart?: any;
  className?: string;
}> = ({ onStart, className }) => {
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [capturing, setCapturing] = useState(false);
  const [finished, setFinished] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
  const [videoEnabled, setVideoEnabled] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    console.log("webcamRef.current", webcamRef.current);
    console.log("webcamRef.current.stream", webcamRef.current?.stream);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current!.stream!, {
      mimeType: MIME_TYPE,
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  useEffect(() => {
    console.log("useEffect Web video");
  }, []);

  const handleDataAvailable = React.useCallback(
    ({ data }: { data: any }) => {
      console.log("Data available");
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
      setFinished(true);
    }
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = React.useCallback(async () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: MIME_TYPE,
      });
      const file = new File([blob], FILE_NAME, { type: MIME_TYPE });

      dispatch(createAsync({ file: file, type: "presentation" }));

      navigate("/speeches");
    }
  }, [recordedChunks]);

  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className="relative flex justify-center items-center">
        <Webcam
          width={640}
          height={480}
          videoConstraints={videoEnabled ? videoConstraints : false}
          mirrored={true}
          audioConstraints={audioConstraints}
          audio={true}
          muted={true}
          ref={webcamRef}
          className="mb-4 rounded-lg bg-violet-400"
        />

        {!videoEnabled && (
          <div className="absolute rounded-full h-36 w-36 border-8 border-white flex items-center justify-center">
            <img src="/assets/videocam_off_white.svg" alt="Video Off" className="w-20 h-20" />
          </div>
        )}

        {!capturing && (
          <div className="absolute top-2 left-2 bg-gray-300 flex items-center rounded-md overflow-hidden">
            <img
              src={videoEnabled ? "/assets/videocam.svg" : "/assets/videocam_off.svg"}
              alt={videoEnabled ? "Video On" : "Video Off"}
              className="py-1 px-2 cursor-pointer"
              onClick={() => setVideoEnabled((prev) => !prev)}
            />

            {/* <div className="bg-black w-px h-5"></div> */}
            
            {/* <img src="/assets/settings.svg" alt="Settings" className="py-1 px-2 cursor-pointer" /> */}
          </div>
        )}

        <div className="absolute top-2 right-2 rounded-md overflow-hidden">
          <MicrophoneIndicator />
        </div>
      </div>

      {capturing ? (
        <UploadBtn className="w-32 h-10" onClick={handleStopCaptureClick}>
          Остановить
        </UploadBtn>
      ) : (
        !finished && (
          <UploadBtn className="w-32 h-10" onClick={handleStartCaptureClick}>
            Начать
          </UploadBtn>
        )
      )}
      {recordedChunks.length > 0 && (
        <UploadBtn className="mt-4 w-32 h-10" onClick={handleDownload}>
          Загрузить
        </UploadBtn>
      )}
    </div>
  );
};

export default WebcamVideo;
