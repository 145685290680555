import { useCallback, useEffect, useRef, useState } from "react";

import UploadBtn from "../../components/ui/buttons/UploadBtn";
import DangerBtn from "../../components/ui/buttons/DangerBtn";
import SuccessBtn from "../../components/ui/buttons/SuccessBtn";
import PrimaryBtn from "../../components/ui/buttons/PrimaryBtn";
import { Link } from "react-router-dom";
import Spinner from "../../components/ui/Spinner";
import BackBtn from "./BackBtn";

const instructions = [
  "Сядьте поближе к краешку стула.",
  "Приподнимите грудь.",
  "Подтяните живот.",
  "Голову держите ровно.",
  "Сожмите губы, а зубы разомкните.",
  "Расслабьте мышцы шеи.",
  "Читайте с закрытым ртом, пытаясь чётко проговаривать все гласные и согласные.",
];

const VentriloquismPage = () => {
  console.log("RERENDER!!!");

  const [state, setState] = useState<"setup" | "started" | "finished">("setup");
  const [textLength, setTextLength] = useState<"short" | "medium" | "long">("short");
  const [dataHandled, setDataHandled] = useState(false);
  const [text, setText] = useState("");

  const genText = async () => {
    const requestOptions: RequestInit = {
      method: "POST",
      credentials: "include",
    };

    try {
      const response = await fetch(`/api/v1/gen_text?length=${textLength}`, requestOptions);
      if (response.ok) {
        type Resp = {
          text: string;
        };

        const data: Resp = await response.json();
        setText(data.text);
        setDataHandled(true);
      } else {
        console.error("Failed to fetch audio list");
      }
    } catch (error) {
      console.error("Error fetching audio list:", error);
    }
  };

  let content;
  if (state === "setup") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col">
        <h1 className="mb-4 text-2xl text-center">Чревовещание</h1>
        <div className="flex items-start justify-between mb-4">
          <p className="font-bold text-xl text-purple-500">Инструкция</p>
          <UploadBtn
            onClick={() => {
              setState("started");
              genText();
            }}
          >
            Начать
          </UploadBtn>
        </div>

        <div className="mb-4">
          <ul>
            {instructions.map((instruction) => (
              <li key={instruction}>
                <span>•</span> {instruction}
              </li>
            ))}
          </ul>
        </div>

        <div className="mb-4 flex items-center gap-4 justify-between">
          <p className="font-medium text-lg">Выберите длину текста</p>
          <div className="text-sm flex gap-3">
            <SuccessBtn
              className={`${textLength === "short" && "border-4 border-black"}`}
              onClick={() => {
                setTextLength("short");
              }}
            >
              Короткий
              <br />
              (1-2 предложения)
            </SuccessBtn>
            <PrimaryBtn
              className={`${textLength === "medium" && "border-4 border-black"}`}
              onClick={() => {
                setTextLength("medium");
              }}
            >
              Средний
              <br />
              (3-5 предложений)
            </PrimaryBtn>
            <DangerBtn
              className={`${textLength === "long" && "border-4 border-black"}`}
              onClick={() => {
                setTextLength("long");
              }}
            >
              Длинный
              <br />
              (6-9 предложений)
            </DangerBtn>
          </div>
        </div>
      </div>
    );
  } else if (state === "started") {
    if (dataHandled) {
      content = (
        <div className="bg-white w-2/3 p-10 rounded-xl flex flex-col gap-8 items-center">
          <p className="text-xl">{text}</p>

          <UploadBtn
            onClick={() => {
              setDataHandled(false);
              setState("finished");
            }}
          >
            Дальше
          </UploadBtn>
        </div>
      );
    } else {
      content = <Spinner />;
    }
  } else if (state === "finished") {
    content = (
      <div className="bg-white p-8 rounded-xl flex flex-col gap-6 items-center">
        <h1 className="text-purple-900 font-bold text-2xl text-center">Вы выполнили упражнение Чревовещание!</h1>
        <p className="text-center w-150">
          Да, это не легко, и первое время смешно, зато данное упражнение значительно улучшит вашу дикцию, постановку
          голоса и речевые навыки.
        </p>
        <div className="w-full flex justify-between">
          <Link to="/exercises">
            <UploadBtn className="">К упражнениям</UploadBtn>
          </Link>
          <UploadBtn
            onClick={() => {
              setState("setup");
            }}
          >
            Пройти снова
          </UploadBtn>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-purple-200 h-screen w-full flex items-center justify-center">
      {content}
      <div className="absolute top-4 left-4">
        <BackBtn />
      </div>
    </div>
  );
};

export default VentriloquismPage;
