export function padZeros(minutes: number): string {
  return minutes.toString().padStart(2, "0");
}

export function formatTimeFromSeconds(totalSeconds: number): string {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  const formattedDate = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  return formattedDate;
}
