import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import FormField from "../../components/ui/fields/FormField";
import UploadBtn from "../../components/ui/buttons/UploadBtn";

import { AppDispatch } from "../../services/state/store";
import { signUpAsync } from "../../services/state/user/userSlice";

const SignUpForm: React.FC = () => {
  const [name, setName] = useState("");
  // const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(signUpAsync({ name, email, password }));
    navigate("/interview");
  };

  return (
    <form className="bg-white shadow-md rounded-2xl px-32 pt-8 pb-8 flex flex-col items-center" onSubmit={handleSubmit}>
      <img src="/assets/logo_white.svg" alt="ProSpeech logo" className="mb-2" />
      <p className="text-xl mb-4">Pro Speech</p>
      <FormField
        id="name"
        placeholder="Имя"
        value={name}
        onChange={(e: any) => setName(e.target.value)}
        className="w-full mb-4"
      />
      {/* <FormField
        id="username"
        placeholder="Username"
        autoComplete="username"
        value={username}
        onChange={(e: any) => setUsername(e.target.value)}
        className="w-full mb-4"
      /> */}
      <FormField
        id="email"
        type="email"
        placeholder="Email"
        autoComplete="username"
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
        className="w-full mb-4"
      />
      <FormField
        id="password"
        type="password"
        placeholder="Пароль"
        autoComplete="new-password"
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
        className="w-full mb-6"
      />
      <UploadBtn className="w-full mb-4" type="submit">
        Зарегистрироваться
      </UploadBtn>
      <p>
        У вас уже есть аккаунт?{" "}
        <Link to="/signin" className="text-violet-500 no-underline hover:underline">
          Войти
        </Link>
      </p>
    </form>
  );
};

export default SignUpForm;
