import { useNavigate } from "react-router-dom";

const BackBtn = () => {
  const navigate = useNavigate();

  return (
    <button className="flex items-center hover:bg-purple-400 px-2 py-1 rounded" onClick={() => navigate(-1)}>
      <img src="/assets/arrow_back.svg" alt="Back" />
      <p className="ml-2 text-xl">Назад</p>
    </button>
  );
};

export default BackBtn;
